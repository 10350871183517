import { gql } from "@apollo/client";


export const GET_USER_BY_ID = gql`
  query GetUser($id: Int!) {
    user_by_pk(id: $id) {
      cognito_id
      agency_id
      first_name
      last_name
      id
      picture
      user_rols {
        id_rol
      }
      worker_id
      user_locations(where: {location: {deleted_at: {_is_null: true}}}) {
        id
        location {
          id
          name
        }
      }
    }
  }
`;

export const GET_ROLES = gql`
  query GetRoles {
    rol(where: {deleted_at: {_is_null: true}}, order_by: { name: asc}) {
      name
      id
      rol_type
    }
  }
`;

export const UPDATE_USER = gql`
  mutation update_an_user($id: Int!,$first_name: String, $last_name: String, $agency_id: Int, $picture: String,  $locations: [user_location_insert_input!]!) {
    delete_user_location(where: {user_id: {_eq: $id}}) {
      affected_rows
    }
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: { first_name: $first_name, last_name: $last_name , agency_id: $agency_id, picture: $picture }
    ) {
      id
      first_name
      last_name
      picture
    }
    insert_user_location(objects: $locations){
      affected_rows
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation update_an_user($id: Int!, $first_name: String, $last_name: String, $agency_id: Int, $picture: String) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: { first_name: $first_name, last_name: $last_name , agency_id: $agency_id, picture: $picture }
    ) {
      id
      first_name
      last_name
      picture
    }
  }
`;

export const UPDATE_USER_ROL = gql`
  mutation update_an_user_rol($id_rol: Int!, $id_user: Int!) {
    update_user_rol(
      _set: { id_rol: $id_rol, id_user: $id_user }
      where: { id_user: { _eq: $id_user } }
    ) {
      returning {
        id
        id_rol
        id_user
      }
    }
  }
`;

 export const ADD_USER = gql`
  mutation AddUser($object: user_insert_input!) {
    insert_user_one(object: $object) {
      first_name
      last_name
      id
      cognito_id
      picture
      agency {
        id
        name
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers($where: user_bool_exp, $pageSize: Int, $offset: Int, $order: user_order_by!) {
    user_aggregate(where: $where) {
      aggregate {
        total: count
      }
    }
    user(where: $where, order_by: [$order], limit: $pageSize, offset: $offset) {
      id
      first_name
      last_name
      picture
      cognito_id
      agency {
        id
        name
      }
      worker {
        first_name
        last_name
        unique_access_id
        picture
      }
      user_rols {
        rol {
          name
        }
      }
      user_locations {
        location {
          id
          name
        }
      }

    }
  }
`;


export const DELETE_USER = gql`
  mutation DeleteUser($id: Int!) {
    update_user_by_pk(
      pk_columns: { id: $id }
     _set: {deleted_at: "now()"}
    ) {
      id
      first_name
      last_name
    }
  }
`;

export const ROLLBACK_DELETE_USER = gql`
  mutation RollbackDeleteUser($id: Int!) {
    update_user_by_pk(
      pk_columns: { id: $id }
     _set: {deleted_at: null}
    ) {
      id
      first_name
      last_name
    }
  }
`;


export const GET_USER_BY_COGNITO_ID = gql`
  query GetUsersByCognitoId($cognitoId: String) {
    user(where: {cognito_id: {_eq: $cognitoId}}) {
      id
      cognito_id
      first_name
      last_name
      picture
      agency {
        id
        name
        locations {
          id
          location {
            id
            allowed_ips
          }
        }
      }
      worker {
        id
        unique_access_id
        position_id
        rate
        markup
        worker_location_rates_aggregate {
          nodes {
            base_rate
            differential
            markup
            id
            location_id
          }
        }
      }
      user_rols {
        rol {
          name
          rol_type
          rol_permissions {
            permission {
              code
            }
          }
        }
      }
      user_locations {
        location {
          id
          name
        }
      }
    }
  }
`;

export const LIST_LINE_LEADERS = gql` 
  query ListLineLeaders($location_where: agency_location_bool_exp) {
    user(where: {user_rols: {rol: {rol_type: {_eq: "LINE_LEADER"}}}, agency: {locations: $location_where}, deleted_at: {_is_null: true}}, order_by: {first_name: asc, full_name: asc}) {
      id
      first_name
      last_name
    }
  }
`;

export const UPDATE_USER_WORKER_ID = gql`
  mutation UpdateUserWorkerId($id: Int!, $workerID: Int!) {
     update_user_by_pk(pk_columns: {id: $id}, _set: {worker_id: $workerID}) {
      id
      first_name
      last_name
  }
  }
`;