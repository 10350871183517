import styles from "../styles/home.module.scss";

export default function Layout({ children, variant, customClasses  }) {
  const getVariantStyle = (variant) => {
    switch (variant) {
      case "dark":
        return "bg-dark";
        break;
      case "gray":
        return "bg-gray";
        break;
      default:
        return null;
    }
  };
  return (
    <div className={`${getVariantStyle(variant)} ${styles.container} ${customClasses}`} >
      {children}
    </div>
  );
}
