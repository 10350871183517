import { useCallback, useEffect, useMemo, useReducer } from "react";

import { useQuery } from "@apollo/client";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { toast } from "react-toastify";
import ReportHeader from "../../components/Reports/ReportHeader";
import Table from "../../components/Table/Table";
import { GET_EVENTS } from "../../graphql/events.graphql";
import {
  dateToString,
  getHourAndMinute,
  timestampToDateString,
} from "../../utils/date-utils";
import { useAuth } from "../../src/context/auth";
import UserActivityFilters from "../../components/Reports/UserActivityFilters";
import SearchBox from "../../components/Filters/SearchBox";

const UserActivity = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { userData, hasRoleType } = useAuth();

  const [filters, setFilters] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    initialFilters
  );

  const [paginationData, setPaginationData] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    { pageSize: 10, pageCount: 0, offset: 0, orderBy: "id", direction: "desc" }
  );

  const { data, loading, error, refetch } = useQuery(GET_EVENTS, {
    variables: {
      ...processVariables(filters, paginationData, userData, hasRoleType),
    },
  });

  const reload = useCallback(() => {
    refetch(processVariables(filters, paginationData, userData, hasRoleType));
  }, [filters, paginationData, refetch, userData, hasRoleType]);

  useEffect(() => {
    reload();
  }, [reload]);

  useEffect(() => {
    setPaginationData({ offset: 0 });
  }, [filters]);

  const events = data?.event || [];

  const columns = useMemo(
    () => [
      {
        Header: t("views.timelogs.date"),
        accessor: "timestamp",
        Cell: (props) => {
          const ts = props.row?.values?.timestamp + "Z";
          return (
            timestampToDateString(ts, language) + " " + getHourAndMinute(ts)
          );
        },
        transformData: (timestamp) => {
          const ts = timestamp + "Z";
          return (
            timestampToDateString(ts, language) + " " + getHourAndMinute(ts)
          );
        },
      },
      {
        Header: t("views.reports.user-activity.user"),
        accessor: "user",
        Cell: (props) => {
          const user = props.row?.values.user;
          const { first_name = "", last_name = "" } = user || {};
          const fullName = first_name + " " + last_name;

          return fullName;
        },
        transformData: (user) => {
          return user ? user.first_name + " " + user.last_name : "";
        },
      },
      {
        Header: t("views.reports.user-activity.type"),
        accessor: "type",
        Cell: (props) => {
          const name = props.row?.values.type;

          return t(`views.reports.user-activity.${name}`);
        },
      },
      {
        Header: t("views.reports.user-activity.report-name"),
        accessor: "report_name",
        Cell: (props) => {
          const name = props.row?.values.report_name;

          return name ? t(`views.sidebar.${name}`) : "-";
        },
      },
    ],
    [t, language]
  );

  const totalElements = data?.event_aggregate?.aggregate?.total || 0;

  const totalPages = Math.ceil(totalElements / paginationData.pageSize);

  const getDataForReport = useQuery(GET_EVENTS, {
    variables: processVariables(
      filters,
      {
        ...paginationData,
        offset: 0,
        pageSize: totalElements,
      },
      userData,
      hasRoleType
    ),
    skip: true,
  });

  if (error) toast.error(t("general.somethingWrong"));

  return (
    <div>
      <ReportHeader
        title={t("views.reports.user-activity.title")}
        filters={filters}
        setFilters={setFilters}
        initialFilters={initialFilters}
        resetFilters={() =>
          setFilters({ ...initialFilters, filter: filters.filter })
        }
        clearableDate
      >
        <UserActivityFilters filters={filters} setFilters={setFilters} />
      </ReportHeader>
      <Table
        title={t("views.reports.user-activity.title")}
        searchBar={
          <SearchBox
            value={filters.filter}
            onChange={(filter) => {
              setPaginationData({ offset: 0 });
              setFilters({ filter });
            }}
            placeholder={t("views.users.searchBy")}
          />
        }
        columns={columns}
        data={events}
        actions={[]}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        totalPages={totalPages}
        loading={loading}
        getDataForReport={getDataForReport.refetch}
        setFilters={setFilters}
        filtersView="user_activity"
        showFiltersList
      />
    </div>
  );
};

export async function getServerSideProps(ctx) {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale, ["common"])),
    },
  };
}

export default UserActivity;

const initialFilters = {
  view: "user_activity",
  filter: "",
  types: [],
  report_names: [],
  date: { startDate: null, endDate: null },
};

const processVariables = (filters, paginationData) => {
  const where = {
    _and: [
      {
        user: {
          _or: [
            { first_name: { _ilike: `%${filters.filter}%` } },
            { last_name: { _ilike: `%${filters.filter}%` } },
          ],
        },
      },
    ],
  };

  if (!!filters.date?.startDate && !!filters.date?.endDate && dateToString(filters.date?.startDate) === dateToString(filters.date.endDate)) {    
    let startDate = `${dateToString(filters.date.startDate)} 00:00:00`;
    let endDate = `${dateToString(filters.date.endDate)} 23:59:59`;

    where["_and"].push({
      timestamp: { _gte: startDate },
    });
    where["_and"].push({
      timestamp: { _lte: endDate },
    });
  } else {
    if (!!filters.date?.startDate) {
      where["_and"].push({
        timestamp: { _gte: dateToString(filters.date?.startDate) },
      });
    }
  
    if (!!filters.date?.endDate) {
      where["_and"].push({
        timestamp: { _lte: dateToString(filters.date?.endDate) },
      });
    }
  }

  if (!!filters.types?.length) {
    where["_and"].push({
      type: { _in: filters.types.map((f) => f.value) },
    });
  }

  if (!!filters.report_names?.length) {
    where["_and"].push({
      report_name: { _in: filters.report_names.map((r) => r.value) },
    });
  }

  const orderBy = paginationData.orderBy || "id";

  let order = {
    id: "desc",
  };

  if (orderBy === "user") {
    order = {
      user: {
        first_name: paginationData.direction,
        last_name: paginationData.direction,
      },
    };
  } else {
    order = {
      [orderBy]: paginationData.direction,
    };
  }

  return { where, ...paginationData, order };
};

export const EVENT_TYPES = {
  VIEW_REPORT: "view-report",
  LOGIN: "login",
};
