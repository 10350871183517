import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";

const useIpHook = () => {
  const { t } = useTranslation();

  const [ip, setIp] = useState("");

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setIp(response?.ip || "");
      })
      .catch((error) => {
        console.log(error);
        setIp(t("views.not-allowed.cannotRecoverIp"));
      });
  }, [t]);

  return ip;
};

export { useIpHook };
