import { roundToTwoDecimals } from "./object-utils";

export const formattedDate = (dateAsString, language) => {
  if (!!dateAsString) {
    const date =
      new Date(dateAsString + "T00:00:00").toLocaleDateString(language) || "";
    return date.replace(/\//g, "-");
  }
  return "";
};

export const timestampToDateString = (date, language) => {
  if (!date) return "";

  const formattedDate = new Date(date);

  const day =
    formattedDate.getDate() < 10
      ? "0" + formattedDate.getDate()
      : formattedDate.getDate();

  const month =
    formattedDate.getMonth() + 1 < 10
      ? "0" + (formattedDate.getMonth() + 1)
      : formattedDate.getMonth() + 1;

  return language == "es"
    ? `${day}/${month}/${formattedDate.getFullYear()}`
    : `${month}/${day}/${formattedDate.getFullYear()}`;
};

export const dateToString = (date) => {
  const finalDate = getFinalDate(date);

  if (!date || finalDate.toString() === "Invalid Date") {
    return "";
  }

  const day = finalDate.getDate();

  const month = finalDate.getMonth() + 1;
  return `${finalDate.getFullYear()}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
};

export const dateWithoutYear = (date, language) => {
  const finalDate = getFinalDate(date);

  if (!date || finalDate.toString() === "Invalid Date") {
    return "";
  }

  const day = finalDate.getDate();

  const month = finalDate.getMonth() + 1;

  if (language === "es") {
    return `${day < 10 ? "0" + day : day}-${month < 10 ? "0" + month : month}`;
  }

  return `${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
};

export const getWeekDay = (date, language) => {
  if (!date) return "";

  const finalDate = getFinalDate(date);

  const options = {
    weekday: "long",
  };
  return new Intl.DateTimeFormat(language, options).format(finalDate);
};

export const getHourAndMinute = (date) => {
  const finalDate = getFinalDate(date);

  if (!date || finalDate.toString() === "Invalid Date") {
    return "";
  }

  const options = {
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Intl.DateTimeFormat("en", options).format(finalDate);
};

export const calculateHoursDifference = (date1, date2) => {
  if (!date1 || !date2) return "";
  const finalDate1 = typeof date1 === "string" ? new Date(date1) : date1;
  const finalDate2 = typeof date2 === "string" ? new Date(date2) : date2;
  const difference = (finalDate1 - finalDate2) / (1000 * 60 * 60);
  return Math.round((difference + Number.EPSILON) * 100) / 100;
};

export const calculateDaysDifference = (date1, date2) => {
  const hoursDifference = calculateHoursDifference(date1, date2);
  return hoursDifference / 24;
};

export const dateToCalendarFormat = (date, language) => {
  const finalDate = getFinalDate(date);

  if (!date || finalDate.toString() === "Invalid Date") {
    return "";
  }

  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  return new Intl.DateTimeFormat(language, options).format(finalDate);
};

export const getFinalDate = (date) =>
  typeof date === "string"
    ? new Date(date.includes("T") ? date : date + "T00:00:00")
    : date;

export const convertMsToTime = (milliseconds) => {
  if (!milliseconds) return "0";

  let seconds = Math.floor(milliseconds / 1000);

  let minutes = roundToTwoDecimals(seconds / 60);

  let hours = roundToTwoDecimals(minutes / 60);

  return roundToTwoDecimals(hours).toFixed(2);
};

export const getLocaleString = (date) => {
  return date.toLocaleString("en-US").replace(" ", "");
};

export const getLocaleDateString = (date) => {
  return date.toLocaleDateString("en-US").replace(" ", "");
};

export const isADate = (stringDate) => {
  // Regular expression to match date format (MM/DD/YYYY)
  const dateRegex =
    /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/(19|20)\d{2}$/;

  // Check if the stringDate matches the date format
  if (!dateRegex.test(stringDate)) {
    return false;
  }

  // Attempt to create a Date object from the stringDate
  const parts = stringDate.split("/");
  const month = parseInt(parts[0], 10);
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  const date = new Date(year, month - 1, day);

  // Check if the Date object is valid
  // (JavaScript's Date object is quite lenient, so we need additional checks)
  return (
    date.getMonth() === month - 1 &&
    date.getDate() === day &&
    date.getFullYear() === year
  );
};

export const getDateFromString = (stringDate) => {
  const parts = stringDate.split("/");
  const month = parseInt(parts[0], 10);
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  return new Date(year, month - 1, day);
};

export const getStringDateRange = (
  startDate = new Date(),
  endDate = new Date(),
  language
) => {
  const startDateString = timestampToDateString(startDate, language);
  const endDateString = timestampToDateString(endDate, language);
  return `${startDateString} - ${endDateString}`;
};
