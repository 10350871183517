import { gql } from "@apollo/client";

export const GET_WORKERS = gql`
query GetWorkers($pageSize: Int, $offset: Int,  $where: worker_bool_exp!, $order: worker_order_by!) {
  worker_aggregate(where: $where) {
    aggregate {
      total: count
    }
  }
  worker(where: $where, order_by: [$order], limit: $pageSize, offset: $offset) {
    id
    first_name
    last_name
    deleted_at
    location {
      id
      name
    }
    agency {
      id
      name
    }
    position {
      id
      comment
      comment_es
    }
    unique_access_id
    ssn
    rate
    markup
    picture
    allow_special_rates
    worker_skills {
      id
      skill {
        description
        id
      }
    }
    description
  }
}
`;

export const DELETE_WORKER = gql`
  mutation DeleteWorker($id: Int!) {
    update_worker_by_pk(
      pk_columns: {id: $id}
      _set: { deleted_at: "now()"}
    ){
      id
      first_name
      last_name
      deleted_at
    }
  }
`


export const ADD_WORKER = gql`
  mutation AddWorker($object: worker_insert_input!) {
    insert_worker_one (object: $object) {
      id
      first_name
      last_name
      unique_access_id
      picture
    }
  }
`;

export const ADD_WORKERS = gql`
  mutation AddWorkers($objects: [worker_insert_input!]!) {
    insert_worker (objects: $objects) {
      returning {
        id
        first_name
        last_name
        unique_access_id
      }
      
    }
  }
`;

export const COUNTER_WORKER_BY_UNIQUE_ID = gql`
  query count_worker_by_unique_id( $filter: String) {
    worker_aggregate(where: {unique_access_id: {_ilike: $filter}}) {
      aggregate {
        count
      }
    }
    worker(where: {unique_access_id: {_ilike: $filter}}) {
      id
      unique_access_id
    }
  }
`

export const GET_WORKER_BY_ID = gql`
  query GetWorkerById($id: Int!) {
    worker_by_pk(id:$id){
      id
      first_name
      last_name
      description
      picture
      deleted_at
      worker_skills {
        id
        skill {
          description
          id
          }
      }
      
      agency {
        id
        name
      }
      location {
        id
        name
      }
     position {
      comment
      comment_es
      id
    }
      markup
      rate
      ssn
      unique_access_id
      allow_special_rates
      worker_location_rates {
        differential
        id
        markup
        location {
          name
        }
        created_at
      }
    }
  }
`

export const ADD_WORKER_HISTORY = gql`
  mutation addWorkerHistory($rate: float8,
        $markup: float8 ,
        $id_worker: Int,
    ){
    insert_worker_history_one(object: {rate: $rate, markup: $markup, id_worker: $id_worker, created_at: "now()"}) {
      id
      id_worker
      markup
      rate
    }
  }
`

export const ADD_WORKERS_HISTORY = gql`
  mutation addWorkersHistory($objects: [worker_history_insert_input!]!) {
    insert_worker_history(objects: $objects) {
      affected_rows
    }
  }
`

export const GET_WORKER_HISTORY_BY_WORKERID = gql`
  query GetWorkerHistoryById($id: Int!,$pageSize: Int,
        $offset: Int, ){
    worker_history_aggregate(where: {id_worker: {_eq: $id}}) {
    aggregate {
      count
    }
  }
    worker_history( 
      limit: $pageSize, 
      offset: $offset where: {id_worker: {_eq: $id}}) {
    rate
    markup
    id
    created_at
  }
  }
`

export const LIST_ALL_SKILLS = gql`
  query Skills {
      skill(where: {deleted_at: {_is_null: true}}, order_by: {description: asc}) {
        id
        description
        description_es
      }
    }
`;

export const LIST_ALL_POSITIONS= gql`
  query Positions {
    position(where: {deleted_at: {_is_null: true}}, order_by: {comment: asc}) {
      id
      code
      comment
      comment_es
      is_misc
      is_standard
      base_rate
    }
  }
`;

export const UPDATE_WORKER= gql`
mutation UpdateWorker($id: Int!, $object: worker_set_input, $worker_skill_insert: [worker_skill_insert_input!]! ) {
  delete_worker_skill(where: {worker_id: {_eq: $id}}) {
    affected_rows
  }
    update_worker_by_pk(pk_columns: {id: $id}, _set: $object) {
          id
          first_name
          
          last_name
          location {
                id
                 name
            }
            agency {
                id
                name
            }
           position {
      comment
      comment_es
      id
    }
            unique_access_id
            ssn
            rate
            markup
            worker_skills {
                id
                skill {
                  description
                  id
                }
            }
    }
    insert_worker_skill(objects: $worker_skill_insert){
      affected_rows
    }
  }`;

export const UPDATE_WORKERS_SKILLS= gql`
  mutation UpdateWorker($workers: [Int!]!, $skills: [worker_skill_insert_input!]! ) {
    delete_worker_skill(where: {worker_id: {_in: $workers}}) {
      affected_rows
    }
    insert_worker_skill(objects: $skills){
      affected_rows
    }
  }
`;

export const UPDATE_WORKERS_LIST = gql`
  mutation UpdateWorkersList($workers: [Int!]!, $att: worker_set_input ) {
    update_worker(where: {id: {_in: $workers}}, _set: $att) {
      affected_rows
    }
  }
`;

export const GET_WORKERS_UNIQUE_ID_REPEATED = gql`
  query repeatedUniqueIdWorkers($unique_access_id_array: [String!]!) {
     worker(where: {unique_access_id: {_in: $unique_access_id_array}}) {
     
      unique_access_id
    }
  }
`;

export const GET_WORKER_BY_UNIQUE_ID = gql`
query GetWorkerByUniqueId($unique_access_id: String!) {
  worker(where: {unique_access_id: {_ilike: $unique_access_id}, deleted_at: {_is_null: true}}) {
    id
    first_name
    last_name
    position_id
    rate
    markup
    allow_special_rates
    users {
      id
    }
    agency {
      id
      locations {
        id
        location_id
        location {
          id
          name
          location_positions {
            base_rate
            position {
              code
            }
          }
        }
      }
    }
  }
}
`;

export const GET_WORKER_DATA_BY_ID = gql`
query GetWorkerDataById($id: Int!) {
  worker(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}) {
    id
    first_name
    last_name
    position_id
    rate
    markup
    unique_access_id
    allow_special_rates
    users {
      id
    }
    agency {
      id
      locations {
        id
        location_id
        location {
          id
          name
          location_positions {
            base_rate
            position {
              code
            }
          }
        }
      }
    }
  }
}
`;

export const LIST_WORKERS_PER_QUERY = gql `
  query GetWorkers($where: worker_bool_exp!, $order: worker_order_by!) {
    worker(where: $where, order_by: [$order]) {
      id
      unique_access_id
      first_name
      last_name
      rate
      markup
      deleted_at
      worker_location_rates_aggregate {
        nodes {
          base_rate
          differential
          markup
          id
          location_id
        }
      }
      location {
        id
        name
      }
      agency {
        id
        name
      }
      position {
        comment
        comment_es
        id
      }
      worker_skills {
        id
        worker_id
        skill_id
        skill {
          description
          id
        }
      }
    }
  }
`;

export const ADD_SPECIAL_RATES = gql `
  mutation AddSpecialRates($objects: [worker_location_rate_insert_input!]!) {
    insert_worker_location_rate(objects: $objects) {
      returning {
        id
        worker_id
        location_id
      }
    }
  }
`;

export const UPDATE_SPECIAL_RATE = gql `
  mutation UpdateSpecialRate($id: Int!, $object: worker_location_rate_set_input!) {
    update_worker_location_rate_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
    }
  }
`;

export const DELETE_SPECIAL_RATE = gql `
  mutation DeleteSpecialRate($id: Int!) {
    delete_worker_location_rate_by_pk(id: $id) {
      id
      worker_id
      differential
    }
  }
`;

export const GET_AVAILABLE_WORKERS_SKILLS = gql `
  query GetAvailableWorkerSkills($args: active_workers_query_args!, $pageSize: Int, $offset: Int, $where: active_worker_bool_exp!, $order: active_worker_order_by!) {
    active_workers_query_aggregate(args: $args, where: $where) {
      aggregate {
        total: count
      }
    }
    active_workers_query(args: $args, where: $where, order_by: [$order], limit: $pageSize, offset: $offset) {
      agency {
        name
      }
      unique_access_id
      name
      position {
        id
        comment
        comment_es
      }
      skillset
      lines
      location {
        name
      }
    }
  }
`;