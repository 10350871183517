import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Auth } from "aws-amplify";

const httpLink = new HttpLink({ uri: process.env.NEXT_PUBLIC_WHATTA_API_URL });

const authLink = setContext(async (_, { headers }) => {
  let token = "";
  try {
    // get the authentication token from local storage if it exists
    const currentSession = await Auth.currentSession();
    if (currentSession) {
      const idToken = currentSession.getIdToken();
      const jwt = idToken.getJwtToken();
      token = `Bearer ${jwt}`;
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  } catch (error) {
    console.log(error);
  }
});

// TODO: Validate UNAUTHENTICATED case
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      console.log(err);
      switch (err.extensions.code) {
        case "UNAUTHENTICATED":
        case "invalid-jwt":
          window.location = "/login";
          return;
        default:
          return;
      }
    }
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, httpLink]),
});

export default client;
