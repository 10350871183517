import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Image from "next/image";
import { useRouter } from "next/router";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Layout from "../components/Layout";
import styles from "../styles/unauthorized.module.scss";
import { useIpHook } from "../utils/ipHook";

const NotAllowed = ({ logout }) => {
  const router = useRouter();

  const { t } = useTranslation();

  const ip = useIpHook();

  return (
    <Layout variant={"dark"}>
      <Navbar
        fixed="top"
        bg="dark"
        variant="dark"
        className={styles["navbar-padding"]}
      >
        <Navbar.Brand
          className={styles["navbar-brand"]}
          onClick={() => router.push("/dashboard")}
        >
          <Image
            alt="logo-white"
            src="/logo-white-vertical.svg"
            width={107}
            height={42}
          />
        </Navbar.Brand>
      </Navbar>
      <div className="d-flex justify-content-center align-items-center bg-dark flex-fill flex-column">
        <div className={styles["inner-container"]}>
          <Image
            alt="lock-img"
            src="/lock-img.svg"
            width={355}
            height={350}
            priority
          />
          <div className={styles["message-container"]}>
            <h6 className={clsx(styles.title, " text-center big-text-bold")}>
              {t("views.not-allowed.title")}
            </h6>
            <p className={styles.message}>
              {" "}
              {t("views.not-allowed.message").replace("%n", ip)}
            </p>
          </div>
        </div>
        <Button variant="primary" onClick={logout} className={styles.button}>
          {t("views.auth.backToLogin")}
        </Button>
      </div>
    </Layout>
  );
};

export async function getStaticProps(ctx) {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale, ["common"])),
    },
  };
}

export default NotAllowed;
