import { useEffect, useState } from "react";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useWindowSize } from "../utils/windowHook";

const Breadcrumbs = () => {
  const router = useRouter();
  const size = useWindowSize();
  const { t, i18n } = useTranslation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const getPathFromUrl = (url) => {
    return url.split(/[?#]/)[0];
  };
  useEffect(() => {
    if (router) {
      const linkPath = router.asPath.split("/");
      linkPath.shift();
      const pathArray = linkPath.map((path, i) => {
        const pathWithoutQueryParams = path.split("?")[0];
        return {
          breadcrumb: i18n.exists(`views.sidebar.${pathWithoutQueryParams}`)
            ? t(`views.sidebar.${pathWithoutQueryParams}`)
            : pathWithoutQueryParams,
          href: getPathFromUrl("/" + linkPath.slice(0, i + 1).join("/")),
          active:
            i === linkPath.length - 1 || pathWithoutQueryParams === "create" || pathWithoutQueryParams === "edit"
              ? true
              : false,
        };
      });
      setBreadcrumbs(pathArray);
    }
  }, [router, t, i18n]);

  return (
    <Breadcrumb>
      {size.width > 480 && breadcrumbs.length >= 1 &&
        breadcrumbs.map((breadcrumb, i) => (
          <Breadcrumb.Item
            key={i}
            linkAs={Link}
            href={breadcrumb.href}
            active={breadcrumb.active}
            style={{ fontSize: 18 }}
          >
            {breadcrumb.breadcrumb}
          </Breadcrumb.Item>
        ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
