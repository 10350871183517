
import { gql } from "@apollo/client";

export const GET_FILTERS = gql`
query Filters( $where: filter_bool_exp!) {
  filter_aggregate(where: $where) {
    aggregate {
      total : count
    }
  },
  filter (where: $where){
    id
    view
    filter_name
    filter_data
    use_current_date
  }
}`;

export const ADD_FILTER = gql`
  mutation AddFilter($object: filter_insert_input!) {
    insert_filter_one (object: $object) {
      id
      view
      filter_name
      filter_data
    }
  }
`;

  export const DELETE_FILTER = gql`
  mutation DeleteFilter ($id: Int!) { 
  update_filter_by_pk(
      pk_columns: { id: $id }
     _set: {deleted_at: "now()"}
    ){
    id
  }
}
`;
