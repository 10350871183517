import { useEffect, useMemo } from "react";
import { useExpanded, usePagination, useSortBy, useTable } from "react-table";

import EmptyMessage from "../EmptyMessage";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import styles from "./Table.module.scss";
import { useTranslation } from "next-i18next";
import { getPaginationOptions } from "../../utils/array-utils";
// import TableBodySticky from "./TableBodySticky";
// import TableHeaderSticky from "./TableHeaderSticky";

const InnerTable = ({
  loading,
  columns,
  data,
  customCellStyles = [],
  paginationData = {},
  setPaginationData = () => {},
  totalPages = 0,
  emptyMessage = "",
  width,
  // sticky = false,
  totalActions = [],
}) => {
  const { t } = useTranslation();

  const pageSize = paginationData.pageSize || 10;

  const orderBy = useMemo(
    () => ({
      id: paginationData.orderBy,
      desc: paginationData.direction === "desc",
    }),
    [paginationData.orderBy, paginationData.direction]
  );

  const index = Math.ceil((paginationData.offset + 1) / pageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      pageCount: totalPages,
      initialState: {
        pageIndex: index - 1,
        pageSize: pageSize,
        sortBy: [orderBy],
      },
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    const { id, desc } = sortBy[0] || {};
    if (id !== orderBy.id || desc !== orderBy.desc)
      setPaginationData({
        orderBy: id,
        direction: desc ? "desc" : "asc",
      });
  }, [sortBy, orderBy, setPaginationData]);

  const paginationOptions = getPaginationOptions(pageCount, index);

  // const Header = sticky ? TableHeaderSticky : TableHeader;

  // const Body = sticky ? TableBodySticky : TableBody;

  return (
    <div className={styles.body}>
      <table {...getTableProps()} className={styles.table}>
        <TableHeader
          customCellStyles={customCellStyles}
          headerGroups={headerGroups}
          totalActions={totalActions}
        />

        {!!data.length && !loading && (
          <TableBody
            page={page}
            prepareRow={prepareRow}
            totalActions={totalActions}
            customCellStyles={customCellStyles}
            width={width}
            {...getTableBodyProps()}
          />
        )}
      </table>

      {!data.length && !loading && (
        <div
          style={{ height: 256 }}
          className={"border-bottom border-secundary"}
        >
          <EmptyMessage
            principal={t("views.table.noData")}
            secondary={emptyMessage || t("views.table.noDataMessage")}
            justSecundary={!!emptyMessage}
          />
        </div>
      )}

      {!!data.length && pageCount > 1 && !loading && (
        <div className={`pagination ${styles["pagination-container"]}`}>
          <button
            type="button"
            onClick={() => {
              previousPage();
              setPaginationData({
                offset: paginationData.offset - paginationData.pageSize,
              });
            }}
            disabled={index <= 1}
            className={styles["pagination-option"]}
          >
            {t("views.table.previous")}
          </button>{" "}
          {paginationOptions.map((option) =>
            option === "first_spacer" || option === "second_spacer" ? (
              <div
                className="d-flex align-items-center justify-content-center mx-2"
                key={option}
              >
                <p className="mb-0">{"..."}</p>
              </div>
            ) : (
              <button
                type="button"
                key={option}
                onClick={() => {
                  gotoPage(option);
                  setPaginationData({
                    offset:
                      (option + 1) * paginationData.pageSize -
                      paginationData.pageSize,
                  });
                }}
                className={`${styles["page-option"]} ${
                  index - 1 === option ? styles["selected-option"] : ""
                }`}
              >
                {option + 1}
              </button>
            )
          )}
          <button
            type="button"
            onClick={() => {
              nextPage();
              setPaginationData({
                offset: paginationData.offset + paginationData.pageSize,
              });
            }}
            disabled={index >= pageCount}
            className={styles["pagination-option"]}
          >
            {t("views.table.next")}
          </button>{" "}
        </div>
      )}
      {loading && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: 560 }}
        >
          <Spinner animation="border" />{" "}
        </div>
      )}
    </div>
  );
};

InnerTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.any.isRequired,
      accessor: PropTypes.string.isRequired,
    }).isRequired
  ),
  data: PropTypes.array.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  customCellStyles: PropTypes.array,
  paginationData: PropTypes.object.isRequired,
  setPaginationData: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  emptyMessage: PropTypes.string,
};

export default InnerTable;
