const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    icon: "bi-house-door-fill",
    paths: [],
  },
  {
    path: "/embedded-dashboard",
    name: "embedded-dashboard",
    icon: "bi bi-bar-chart-fill",
    paths: [],
  },
  {
    path: "/users",
    name: "users",
    icon: "bi-person",
    paths: [
      { name: "list", path: "/users" },
      { name: "add", path: "/users/add" },
    ],
  },
  {
    path: "/roles",
    name: "roles",
    icon: "bi-person",
    paths: [
      { name: "list", path: "/roles" },
      { name: "add", path: "/roles/add" },
    ],
  },
  {
    path: "/locations",
    name: "locations",
    icon: "bi-pin-map-fill",
    paths: [
      { name: "list", path: "/locations" },
      { name: "add", path: "/locations/add" },
    ],
  },
  {
    path: "/agencies",
    name: "agencies",
    icon: "bi-people",
    paths: [
      { name: "list", path: "/agencies" },
      { name: "add", path: "/agencies/add" },
    ],
  },
  {
    path: "/customers",
    name: "customers",
    icon: "bi-bag",
    paths: [
      { name: "list", path: "/customers" },
      { name: "add", path: "/customers/add" },
    ],
  },
  {
    path: "/itemtypes",
    name: "item_type",
    icon: "bi-bar-chart",
    paths: [
      { name: "list", path: "/itemtypes" },
      { name: "add", path: "/itemtypes/add" },
    ],
  },
  {
    path: "/items",
    name: "items",
    icon: "bi-box-seam",
    paths: [
      { name: "list", path: "/items" },
      { name: "add", path: "/items/add" },
    ],
  },
  {
    path: "/skills",
    name: "skills",
    icon: "bi-bar-chart",
    paths: [
      { name: "list", path: "/skills" },
      { name: "add", path: "/skills/add" },
    ],
  },
  {
    path: "/special-rates",
    name: "specialrate",
    icon: "bi-bar-chart",
    paths: [
      { name: "list", path: "/special-rates" },
      { name: "add", path: "/special-rates/add" },
    ],
  },
  {
    path: "/positions",
    name: "positions",
    icon: "bi-bar-chart",
    paths: [
      { name: "list", path: "/positions" },
      { name: "add", path: "/positions/add" },
    ],
  },
  {
    path: "/workers",
    name: "workers",
    icon: "bi-person",
    paths: [
      { name: "list", path: "/workers" },
      { name: "add", path: "/workers/add" },
      { name: "import", path: "/workers/import" },
      { name: "imported", path: "/workers/imported" },
      { name: "skills", path: "/workers/skills" },
      { name: "rates", path: "/workers/rates" },
    ],
  },
  {
    path: "/equipments",
    name: "equipments",
    icon: "bi-bar-chart",
    paths: [
      { name: "list", path: "/equipments" },
      { name: "add", path: "/equipments/add" },
    ],
  },
  {
    path: "/pallets",
    name: "pallet",
    icon: "bi-bar-chart",
    paths: [
      { name: "list", path: "/pallets" },
      { name: "add", path: "/pallets/add" },
    ],
  },
  {
    path: "/jobtypes",
    name: "jobtype",
    icon: "bi-bar-chart",
    paths: [
      { name: "list", path: "/jobtypes" },
      { name: "add", path: "/jobtypes/add" },
    ],
  },
  {
    path: "/lines",
    name: "productionlines",
    icon: "bi-calendar3",
    paths: [
      { name: "list", path: "/lines" },
      { name: "add", path: "/lines/add" },
    ],
  },
  {
    path: "/jobs",
    name: "linejobs",
    icon: "bi-nut-fill",
    paths: [
      { name: "list", path: "/jobs" },
      { name: "add", path: "/jobs/add" },
      { name: "approve", path: "/jobs/approve" },
      { name: "bulk-update", path: "/jobs/bulk-update" },
    ],
  },
  {
    path: "/timelogs",
    name: "timelog",
    icon: "bi-bar-chart",
    paths: [
      { name: "list", path: "/timelogs" },
      { name: "add", path: "/timelogs/add" },
    ],
  },
  {
    path: "/reports",
    name: "reports",
    icon: "bi-bar-chart",
    paths: [
      { name: "workers-skill", path: "/reports/workers-skill" },
      { name: "workers-list", path: "/reports/workers-list" },
      { name: "daily-logged", path: "/reports/daily-logged" },
      { name: "daily-movement", path: "/reports/daily-movement" },
      { name: "labor-by-line", path: "/reports/labor-by-line" },
      { name: "daily-by-location", path: "/reports/daily-by-location" },
      { name: "daily-by-agency", path: "/reports/daily-by-agency" },
      {
        name: "daily-by-agency-with-prices",
        path: "/reports/daily-by-agency-with-prices",
      },
      { name: "weekly-by-agency", path: "/reports/weekly-by-agency" },
      { name: "expected-labor", path: "/reports/expected-labor" },
      { name: "expected-production", path: "/reports/expected-production" },
      {
        name: "daily-by-customer-item",
        path: "/reports/daily-by-customer-item",
      },
      { name: "daily-pandl", path: "/reports/daily-production" },
      { name: "comparison-pandl", path: "/reports/comparison-pandl" },
      { name: "daily-pandl-misc", path: "/reports/daily-production-misc" },
      { name: "overlapped-timelogs", path: "/reports/overlapped-timelogs" },
      { name: "audit", path: "/reports/audit" },
      { name: "audit-timelogs", path: "/reports/audit-timelogs" },
      { name: "user-activity", path: "/reports/user-activity" },
    ],
  },
];

export default dashboardRoutes;
