import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import PropTypes from "prop-types";
import styles from "./SearchBox.module.scss";
import { useRef } from "react";

const SearchBox = ({
  value = "",
  onChange,
  width = "100%",
  hideIcon = false,
  instantChange,
  ...props
}) => {

  const filterRef = useRef({value});

  filterRef.current.defaultValue = value
  filterRef.current.value = value

  let currentTimer;

  const handleChange = (e) => {
    const newText = e.target.value;

    !!currentTimer && clearTimeout(currentTimer);
    if (!instantChange) {
      currentTimer = setTimeout(() => {
        onChange(newText);
      }, [750]);
      filterRef.current.value = newText;
    } else {
      onChange(newText);
    }
  };

  return (
    <InputGroup
      className={`${styles["search-container"]} mb-3 mb-md-0`}
      style={{ width, minWidth: 200 }}
    >
      {!hideIcon && (
        <InputGroup.Text className={styles["search-icon"]}>
          {" "}
          <i className="bi bi-search"></i>
        </InputGroup.Text>
      )}
      <Form.Control
        ref={filterRef}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={handleChange}
        {...props}
        type="text"
        className={styles["search-box"]}
      />
    </InputGroup>
  );
};

SearchBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default SearchBox;
