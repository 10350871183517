import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SubmitButton from "../SubmitButton";
import { useTranslation } from "next-i18next";
import CheckboxInput from "../CheckboxInput";
import { useState } from "react";

const TableConfiguration = ({ open, columns = [], onClose, onSubmit }) => {
  const { t } = useTranslation();

  const [data, setData] = useState(columns);

  return (
    <Modal show={open} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">
          <h1 className="mb-0 fw-bold">{t("views.table.configuration")}</h1>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-0">
        <div
          className="d-flex justify-content-between w-100 px-5 py-3 border-bottom border-secondary"
          style={{ backgroundColor: "#d0dde6" }}
        >
          <p className="mb-0 fw-bold">{t("views.table.columnName")}</p>
          <p className="mb-0 fw-bold">{t("views.table.forTable")}</p>
          <p className="mb-0 pe-2 fw-bold">{t("views.table.show")}</p>
        </div>
        {data.filter(c=> c.accessor !== "default").map((column) => (
          <div
            key={column.accessor}
            className="d-flex justify-content-between align-items-center w-100 px-5 border-bottom border-secondary"
          >
            <p className="mb-0 w-50">{column.Header}</p>
            <div className="d-flex w-50 justify-content-between align-items-center">
              {!!column.forReports ? (
                <i
                  className="bi bi-check-circle-fill ps-3 pb-1"
                  style={{ fontSize: 14,  color: "#244bc4" }}
                />
              ) : (
                <span />
              )}

              <CheckboxInput
                label=""
                checked={!column.hidden}
                onChange={() => {
                  const updatedColumns = Array.from(data);
                  const index = updatedColumns.findIndex(
                    (c) => c.accessor === column.accessor
                  );
                  const newValue = !updatedColumns[index].hidden;
                  updatedColumns[index].hidden = newValue;
                  setData(updatedColumns);
                }}
              />
            </div>
          </div>
        ))}
      </Modal.Body>

      <Modal.Footer
        className="d-flex justify-content-center"
        style={{ gap: 8 }}
      >
        <Button variant="secondary" onClick={onClose}>
          {t("general.cancel")}
        </Button>
        <SubmitButton
          label={t("general.save").toUpperCase()}
          loading={false}
          onClick={() => onSubmit(data)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default TableConfiguration;
