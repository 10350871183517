import { Button } from "react-bootstrap";
import DateRangeFilter from "../Filters/DateRangeFilter";
import FiltersButton from "../Filters/FiltersButton";
import clsx from "clsx";
import tableStyles from "../Table/Table.module.scss";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { useWindowSize } from "../../utils/windowHook";
import SaveFilterModal from "../Table/SaveFilterModal";

const ReportHeader = ({
  title,
  filters,
  setFilters,
  children,
  hideDate = false,
  hideFilterButton = false,
  resetFilters,
  clearableDate = false,
}) => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const [openFilter, setOpenFilters] = useState(false);
  const [openSaveFiltersForm, setOpenSaveFiltersForm] = useState(false);

  const saveFilter = () => {
    setOpenSaveFiltersForm(true);
  };

  return (
    <>
      {/* HEADER */}
      <div className="w-100 d-flex flex-column flex-md-row align-items-center">
        <div className="w-50">
          <h1>{title}</h1>
        </div>
        <div className="w-100 d-flex flex-column flex-md-row justify-content-md-end align-items-start p-3">
          <div
            className={clsx(
              tableStyles["table-header-buttons"],
              "d-flex flex-row justify-content-evenly ps-0 ps-md-3"
            )}
          >
            {!hideFilterButton && (
              <FiltersButton
                onChange={() => setOpenFilters(!openFilter)}
                className={clsx(
                  tableStyles["filters-button"],
                  openFilter && tableStyles["active-filter"],
                  "mx-2"
                )}
                size={size}
              />
            )}
            {!hideDate && (
              <div style={{ margin: -16 }}>
                <DateRangeFilter
                  title={t("views.jobs.form.date")}
                  values={filters.date}
                  onChange={(date) => setFilters({ date })}
                  clearable={clearableDate}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* FILTERS */}
      <div
        className={tableStyles.filters}
        style={{
          width: "100%",
          display: openFilter ? "block" : "none",
        }}
      >
        <div
          className="bg-dark ps-3 w-100 d-flex justify-content-between align-items-center"
          style={{ height: 48 }}
        >
          <p className="mb-0 fw-bold text-light">{t("views.table.filterBy")}</p>
          <i
            className="text-light bi bi-x-lg me-3"
            style={{ cursor: "pointer" }}
            onClick={() => setOpenFilters(false)}
          ></i>
        </div>

        <div className="w-100 d-flex flex-column flex-sm-row justify-content-end align-items-start align-items-sm-center pe-0 pe-sm-3">
          <div
            className="d-flex justify-content-center me-0 me-sm-3 flex-wrap"
            style={{ width: "-webkit-fill-available" }}
          >
            {children}
          </div>

          <div className="d-flex flex-column py-3">
            <div
              className={`${tableStyles["reset-container"]} d-flex justify-content-center mb-2`}
            >
              <Button
                className="m-3 m-sm-0"
                variant="secondary"
                style={{ minWidth: 180 }}
                onClick={resetFilters}
              >
                {t("views.table.reset").toUpperCase()}
              </Button>
            </div>
            <div
              className={`${tableStyles["reset-container"]} d-flex justify-content-center`}
            >
              <Button
                className="m-3 m-sm-0"
                variant="primary"
                style={{ minWidth: 180 }}
                onClick={saveFilter}
              >
                {t("views.table.saveFilters")}
              </Button>
            </div>
          </div>
        </div>
        <SaveFilterModal
          open={openSaveFiltersForm}
          toggleModal={() => setOpenSaveFiltersForm((prev) => !prev)}
          data={filters}
        />
      </div>
    </>
  );
};

export default ReportHeader;
