import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { useTranslation } from "next-i18next";

const SubmitButton = ({ label, loading, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button variant="primary" type="submit" disabled={loading} {...props}>
      {loading ? t("views.auth.pending") : label}
    </Button>
  );
};

SubmitButton.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SubmitButton;
