import Head from "next/head";

const HeadLayout = () => {
    return  (
        <Head>
        <title>WHATTA</title>
        <meta
        name="description"
        content="Wheelhouse Agile Time Tracking Application"
        />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
        />
    </Head>
    )
}

export default HeadLayout;