import React from "react";

import Form from "react-bootstrap/Form";

const CheckboxInput = ({
  indeterminate,
  containerClass = "",
  onChange,
  preventDefault = false,
  ...props
}) => {
  const checkboxRef = React.useRef();
  if (!!checkboxRef.current) checkboxRef.current.indeterminate = indeterminate;
  return (
    <div
      onClick={(e) => {
        if (preventDefault) {
          e.preventDefault();
          e.stopPropagation();
        }
        onChange(e);
      }}
      className={
        containerClass
          ? containerClass
          : "d-flex justify-content-center align-items-center"
      }
      style={{ minWidth: props.minWidth ?? 40, height: 40, maxHeight: "100%" }}
    >
      <Form.Check
        ref={checkboxRef}
        type="checkbox"
        onChange={(e) => (preventDefault ? onChange(e) : null)}
        {...props}
      />
    </div>
  );
};

export default CheckboxInput;
