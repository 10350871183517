import "../styles/globals.css";
import "../styles/globals.scss";
import "@aws-amplify/ui-react/styles.css"; // default theme
import "react-toastify/dist/ReactToastify.css";

import { CognitoIdentityServiceProvider, CognitoS3ServiceProvider } from "../aws-client";

import { Amplify } from "aws-amplify";
import { ApolloProvider } from "@apollo/client";
import { AuthProvider } from "../src/context/auth";
import ErrorBoundary from "../components/Error/ErrorBoundary";
import HeadLayout from "../components/Head";
import SSRProvider from "react-bootstrap/SSRProvider";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import { ToastContainer } from "react-toastify";
import { WorkersProvider } from "../src/context/workers";
import { appWithTranslation } from "next-i18next";
import awsconfig from "../aws-exports";
import client from "../apollo-client";
import { TimelogsProvider } from "../src/context/timelogs";

Amplify.configure(awsconfig);

function MyApp({ Component, pageProps }) {
  return (
    <SSRProvider>
      <ThemeProvider>
        <ApolloProvider client={client}>
          <ErrorBoundary>
            <AuthProvider
              cognitoIdentityServiceProvider={CognitoIdentityServiceProvider}
              cognitoS3ServiceProvider={CognitoS3ServiceProvider}
            >
              <WorkersProvider>
                <TimelogsProvider>
                  <HeadLayout></HeadLayout>
                  <Component {...pageProps} />{" "}
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    theme="colored"
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />{" "}
                </TimelogsProvider>
              </WorkersProvider>
            </AuthProvider>
          </ErrorBoundary>
        </ApolloProvider>
      </ThemeProvider>
    </SSRProvider>
  );
}

export default appWithTranslation(MyApp);
