import ActionsDropdown from "../ActionsDropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import clsx from "clsx";
import styles from "./Table.module.scss";
import Link from "next/link";

export const TableBody = ({
  page,
  prepareRow,
  customCellStyles,
  totalActions,
  width = 100,
  ...props
}) => {
  const tabletSize = !!window && window?.innerWidth <= 1200;
  const handleClick = (e, action) => {
    if (!action.disabled) {
      action.onClick(e);
    }
  };
  return (
    <tbody {...props} style={{ overflow: "auto" }}>
      {page.map((row, rIndex) => {
        prepareRow(row);

        const finalActions = totalActions
          .filter(() => !row.canExpand)
          .map((action) => {
            const { disable } = action;
            const disabled =  !(!disable || !disable(row.original))
            return {
              ...action,
              disabled,
              onClick: (e) => {
                e.stopPropagation();
                action.onClick(
                  action.withSubRow
                    ? {
                        ...row.original,
                        subRow: row.depth > 0,
                      }
                    : row.original
                );
              },
              redirect: () => {
                const path = action.redirect && !disabled
                  ? action.redirect(row.original)
                  : "";
                return path;
              },
            };
          });

        return (
          <tr
            key={rIndex}
            {...row.getRowProps()}
            className={clsx(
              styles.row,
              row.depth > 0 && styles["border-y-zero"]
            )}
          >
            {row.cells.map((cell, cIndex) => {
              const render = cell.render("Cell");

              const renderData = (answer) =>
                !!cell.column?.transformData
                  ? cell.column?.transformData(answer, row)
                  : answer;

              let ariaDescriber = "";
              if (Array.isArray(cell.value)) {
                cell.value.forEach((v) => {
                  ariaDescriber += renderData(v) + "; ";
                });
              } else {
                ariaDescriber = renderData(cell.value);
              }

              const hasValue =
                (!!cell.value && !Array.isArray(cell.value)) ||
                Array.isArray(cell.value);

              const cellClassName = clsx(
                !customCellStyles[cIndex]
                  ? styles.cell
                  : customCellStyles[cIndex],
                (row.isExpanded || row.depth > 0) && styles["expanded-color"]
              );
              return !!hasValue && !cell.column.disabledTooltip ? (
                <OverlayTrigger
                  show={false} // Tooltips are deactivate according to user feedback if possible in the future
                  key={cIndex} // we should implement tooltips that just appear for truncated texts
                  placement={"bottom-start"}
                  overlay={<Tooltip id={ariaDescriber}>{render}</Tooltip>}
                >
                  <td
                    key={cIndex}
                    {...cell.getCellProps()}
                    className={cellClassName}
                    style={{
                      maxWidth: `${width / (row.cells.length + 1)}vw`,
                      minWidth: 150,
                    }}
                  >
                    {render}
                  </td>
                </OverlayTrigger>
              ) : (
                <td
                  key={cIndex}
                  {...cell.getCellProps()}
                  className={cellClassName}
                  style={{
                    maxWidth: `${width / (row.cells.length + 1)}vw`,
                    minWidth: 150,
                  }}
                >
                  {render}
                </td>
              );
            })}
            {!!totalActions.length && (
              <React.Fragment>
                <td
                  style={{ minWidth: tabletSize ? 64 : 230 }}
                  className={
                    row.isExpanded || row.depth > 0
                      ? styles["expanded-color"]
                      : ""
                  }
                />
                <td
                  className={clsx(
                    styles["actions-container"],
                    row.depth > 0 && "border-bottom border-secondary",
                    row.depth > 0 && styles["expanded-color"]
                  )}
                  style={{
                    minWidth: tabletSize ? 64 : 230,
                    maxWidth: tabletSize ? 64 : 230,
                    paddingLeft: tabletSize ? 0 : 16,
                  }}
                >
                  {tabletSize ? (
                    <ActionsDropdown actions={finalActions} />
                  ) : (
                    finalActions.map((action) => {
                      const overlayProps = {
                        placement: "bottom-end",
                      };
                      if (action.disabled) {
                        overlayProps.show = false;
                      }

                      return (
                        <LinkWrapper key={action.name} path={action.redirect()}>
                          <OverlayTrigger
                            {...overlayProps}
                            overlay={
                              <Tooltip id={`tooltip-${action.name}`}>
                                {action.name}
                              </Tooltip>
                            }
                          >
                            <i
                              role="button"
                              className={`${action.icon} ${styles.action} ${
                                action.disabled && styles["icon-disabled"]
                              }`}
                              onClick={(e) => handleClick(e, action)}
                            />
                          </OverlayTrigger>
                        </LinkWrapper>
                      );
                    })
                  )}
                </td>
              </React.Fragment>
            )}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;

const LinkWrapper = ({ path = "", children }) =>
  !!path ? (
    <Link passHref href={path}>
      <a className="text-reset">{children}</a>
    </Link>
  ) : (
    children
  );
