import { useTranslation } from "next-i18next";

const EmptyMessage = ({
  principal = "",
  secondary = "",
  justSecundary = false,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center w-100 h-100"
    >
      <i style={{ fontSize: 76, color: "#818a91" }} className="bi bi-inbox"></i>
      {!justSecundary && (
        <h2 style={{ fontWeight: 600, color: "#818a91" }}>
          {principal || t("views.table.noData")}
        </h2>
      )}
      <h4 style={{ color: "#818a91" }}>
        {secondary || t("views.table.noDataMessage")}
      </h4>
    </div>
  );
};

export default EmptyMessage;
