import { gql } from "@apollo/client";

export const ADD_EVENT= gql`
  mutation AddEvent($object: event_insert_input!) {
    insert_event_one (object: $object) {
        id
    }
  }
`;

export const GET_EVENTS = gql`
  query GetEvents(
  $filter:String
  $pageSize:Int
  $offset:Int
  $where: event_bool_exp!
  $order: event_order_by!
  ){
    event_aggregate(where: $where) {
      aggregate {
      total: count
      }
    }
    event(
       where: $where
      order_by: [$order]
      limit: $pageSize
      offset: $offset
    ){
      id
      user {
        id
        first_name
        last_name
      }
      type
      report_name
      timestamp
    }
  }
`;