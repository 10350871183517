import React, { forwardRef } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import PropTypes from "prop-types";
import Link from "next/link";

const ActionsDropdown = ({ actions = [] }) => {
  if (!actions.length) return <React.Fragment />;

  return (
    <Dropdown align="end" className="mx-2">
      <Dropdown.Toggle as={ToggleButton} id="dropdown-language" />

      <Dropdown.Menu aaling="start">
        {actions.map((action, index) => (
          <LinkWrapper key={index} path={action.redirect()}>
            <Dropdown.Item
              disabled={action.disabled}
              eventKey={action.name}
              onClick={action.onClick}
            >
              <i role="button" className={`${action.icon} pe-2`} />
              {action.name}
            </Dropdown.Item>
          </LinkWrapper>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ToggleButton = forwardRef(function Toggle({ onClick }, ref) {
  return (
    <div className="d-flex">
      <a
        ref={ref}
        href=""
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="d-flex align-items-center justify-content-center border border-secondary rounded-circle"
        style={{
          height: 34,
          width: 34,
        }}
      >
        <i
          className="bi bi-three-dots-vertical"
          style={{
            fontSize: 20,
          }}
        />
      </a>
    </div>
  );
});

ActionsDropdown.propTypes = {
  actions: PropTypes.array.isRequired,
};

export default ActionsDropdown;

const LinkWrapper = ({ path = "", children }) =>
  !!path ? (
    <Link passHref href={path}>
      {children}
    </Link>
  ) : (
    children
  );
