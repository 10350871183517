import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRangePicker, createStaticRanges } from "react-date-range";
import { startOfYear, endOfYear, addYears } from "date-fns";
import DropdownToggle from "./DropdownToggle";
import { es, enUS } from "react-date-range/dist/locale";

import { useState } from "react";
import {
  dateToCalendarFormat,
  getDateFromString,
  getFinalDate,
  timestampToDateString,
} from "../../utils/date-utils";
import { useTranslation } from "next-i18next";
import { defaultStaticRanges } from "react-date-range/dist/defaultRanges";
import styles from "./SearchBox.module.scss";
import DateTextInput from "../DatePicker/DateTextInput";

const DateRangeFilter = ({
  title,
  values = {},
  onChange,
  clearable = false,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [state, setState] = useState(() => {
    return [
      {
        startDate: getFinalDate(values.startDate) || null,
        endDate: getFinalDate(values.endDate) || null,
        key: "selection",
      },
    ];
  });

  const staticRangesLabels = {
    Today: t("views.filters.date.today"),
    Yesterday: t("views.filters.date.yesterday"),
    "This Week": t("views.filters.date.week"),
    "Last Week": t("views.filters.date.lastWeek"),
    "This Month": t("views.filters.date.thisMonth"),
    "Last Month": t("views.filters.date.lastMonth"),
  };

  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label]
        ? { ...item, label: dictionary[item.label] }
        : item;
  }

  const defineds = {
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1)),
  };

  const staticRanges = [
    ...defaultStaticRanges.map(translateRange(staticRangesLabels)),
    ...createStaticRanges([
      {
        label: t("views.filters.date.thisYear"),
        range: () => ({
          startDate: defineds.startOfYear,
          endDate: defineds.endOfYear,
        }),
      },
      {
        label: t("views.filters.date.lastYear"),
        range: () => ({
          startDate: defineds.startOflastYear,
          endDate: defineds.endOflastYear,
        }),
      },
    ]),
  ];

  const getLabel = (values) => {
    if (values.startDate && values.endDate) {
      return (
        dateToCalendarFormat(values.startDate, language) +
        " - " +
        dateToCalendarFormat(values.endDate, language)
      );
    } else {
      return "min - max";
    }
  };

  let width = 0;

  if (typeof window !== "undefined") {
    width = window?.innerWidth;
  }

  return (
    <DropdownToggle
      title={title}
      label={getLabel(values)}
      values={state}
      onChange={() =>
        onChange({
          startDate: state[0].startDate,
          endDate: state[0].endDate,
        })
      }
      primaryLabel={!!values.startDate || !!values.endDate}
      resetOptions={() =>
        setState([
          {
            startDate: values.startDate,
            endDate: values.endDate,
            key: "selection",
          },
        ])
      }
      clearSelection={
        values.startDate && values.endDate && clearable
          ? () =>
              onChange({
                startDate: null,
                endDate: null,
              })
          : null
      }
      date
      filter={
        <div
          className="px-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <DateTextInput
            onDateChange={(maskedInput) => {
              const startDate = maskedInput.trim();
              const endDate = state[0]
                ? timestampToDateString(state[0].endDate, language)
                : startDate;

              if (getDateFromString(startDate) <= getDateFromString(endDate)) {
                setState([
                  {
                    startDate: getDateFromString(startDate),
                    endDate: getDateFromString(endDate),
                    key: "selection",
                  },
                ]);
              } else {
                setState([
                  {
                    startDate: getDateFromString(startDate),
                    endDate: getDateFromString(startDate),
                    key: "selection",
                  },
                ]);
              }
            }}
            customStyles={styles["start-date"]}
            value={timestampToDateString(state[0]?.startDate, language) || ""}
          />

          <DateTextInput
            onDateChange={(maskedInput) => {
              const endDate = maskedInput.trim();
              const startDate = state[0]
                ? timestampToDateString(state[0].startDate, language)
                : endDate;
              if (getDateFromString(startDate) <= getDateFromString(endDate)) {
                setState([
                  {
                    startDate: getDateFromString(startDate),
                    endDate: getDateFromString(endDate),
                    key: "selection",
                  },
                ]);
              } else {
                setState([
                  {
                    startDate: getDateFromString(endDate),
                    endDate: getDateFromString(endDate),
                    key: "selection",
                  },
                ]);
              }
            }}
            customStyles={styles["end-date"]}
            value={timestampToDateString(state[0]?.endDate, language) || ""}
          />

          <DateRangePicker
            locale={language === "es" ? es : enUS}
            onChange={(item) => setState([item.selection])}
            ranges={[
              {
                startDate: getFinalDate(state[0]?.startDate) || new Date(),
                endDate: getFinalDate(state[0]?.endDate) || new Date(),
                key: "selection",
              },
            ]}
            direction={width > 1380 ? "horizontal" : "vertical"}
            months={2}
            inputRanges={[]}
            staticRanges={staticRanges}
            className={styles.datepicker}
          />
        </div>
      }
    />
  );
};

export default DateRangeFilter;
