import React from "react";
import { useMask } from "@react-input/mask";
import FormControl from "react-bootstrap/FormControl";
import clsx from "clsx";
import styles from "../TextInput/TextInputWithFormik.module.scss";
import { isADate } from "../../utils/date-utils";

const DATE_PLACEHOLDER = "MM/DD/YYYY";

const DateTextInput = ({
  customStyles,
  onDateChange,
  value,
  ...inputProps
}) => {
  const [innerValue, setInnerValue] = React.useState(value);

  const inputRef = useMask({
    mask: "__/__/____",
    replacement: { _: /\d/ },
  });

  React.useEffect(() => setInnerValue(value), [value]);

  return (
    <FormControl
      ref={inputRef}
      className={clsx(styles["date-range-input"], customStyles)}
      placeholder={DATE_PLACEHOLDER}
      onChange={(event) => {
        const maskedInput = event.target.value || "";
        setInnerValue(maskedInput);

        const isValid = isADate(maskedInput.trim());
        isValid && onDateChange(maskedInput);

        if (maskedInput.trim().length === DATE_PLACEHOLDER.length && !isValid) {
          setInnerValue(value);
        }
      }}
      value={innerValue}
      {...inputProps}
    />
  );
};

export default DateTextInput;
