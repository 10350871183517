import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import styles from "../../styles/404.module.scss";
import Layout from "../Layout";

const ErrorPage = ({ onClick = () => {} }) => {
  const router = useRouter();

  const { t } = useTranslation();

  return (
    <Layout variant={"dark"}>
      <Navbar
        fixed="top"
        bg="dark"
        variant="dark"
        className={styles["navbar-padding"]}
      >
        <Navbar.Brand
          className={styles["navbar-brand"]}
          onClick={() => router.push("/dashboard")}
        >
          <Image
            alt="logo-white"
            src="/logo-white-vertical.svg"
            width={107}
            height={42}
          />
        </Navbar.Brand>
      </Navbar>
      <div className="d-flex justify-content-center align-items-center bg-dark flex-fill flex-column">
        <div className={styles["inner-container"]}>
          <i
            style={{ color: "white", fontSize: 200, margin: "auto" }}
            className="bi bi-tools"
          ></i>
          <div className={styles["message-container"]}>
            <p className={styles.message}>{t("views.error.mainMessage")}</p>
            <p className={styles.message}>
              {t("views.error.secondMessage")}
            </p>
          </div>
        </div>
        <Button
          variant="primary"
          onClick={() => {
            router.back();
            onClick();
          }}
          className={styles.button}
        >
          {t("general.goBack")}
        </Button>
      </div>
    </Layout>
  );
};


export default ErrorPage;
