import { createContext, useContext, useEffect, useState } from "react";

import { useRouter } from "next/router";

const WorkersContext = createContext({});

export const WorkersProvider = ({ children }) => {
  const [importedWorkers, setImportedWorkers] = useState(null);
  const router = useRouter();

  const handleSetImportedWorkers = (value) => {
    setImportedWorkers(value);
    localStorage.setItem("importedWorkers", JSON.stringify(value));
  };



  useEffect(() => {
    if (!["/workers/imported"].includes(router.pathname)) return;

    if (importedWorkers === null) {
      setImportedWorkers(
        JSON.parse(localStorage.getItem("importedWorkers") || "[]")
      );
    }
  }, [importedWorkers, router.pathname, router.query]);

  return (
    <WorkersContext.Provider
      value={{ importedWorkers, setImportedWorkers: handleSetImportedWorkers }}
    >
      {children}
    </WorkersContext.Provider>
  );
};

export const useWorkers = () => useContext(WorkersContext);
