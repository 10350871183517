import { useTranslation } from "next-i18next";
import styles from "./Table.module.scss";

const TableHeader = ({ customCellStyles, headerGroups, totalActions }) => {
  const { t } = useTranslation();
  const tabletSize = !!window && window?.innerWidth <= 1200;
  return (
    <thead>
      {headerGroups.map((headerGroup, index) => (
        <tr
          key={index}
          {...headerGroup.getHeaderGroupProps()}
          className={`${styles["table-title-container"]} ${styles["column-title-container"]}`}
        >
          {headerGroup.headers.map((column, index) => {
            return (
              <th
                key={index}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={`${styles["column-title"]} ${
                  column.isSorted ? styles["sorted-title"] : ""
                }
                ${!!customCellStyles[index] ? customCellStyles[index] : ""}`}
              >
                <span>
                  {column.canSort &&
                    (column.isSorted && !column.isSortedDesc ? (
                      <i
                        className={`bi bi-caret-up-fill ${styles["icon"]}`}
                        style={{ color: "#244bc4" }}
                      />
                    ) : (
                      <i
                        className={`bi bi-caret-down-fill ${styles["icon"]}`}
                        style={{
                          color: column.isSortedDesc ? "#244bc4" : "",
                        }}
                      />
                    ))}
                </span>
                {column.render("Header")}
              </th>
            );
          })}
          {!!totalActions.length && (
            <th
              className={styles["action-column-title"]}
              style={{
                minWidth: tabletSize ? 64 : 230,
                maxWidth: tabletSize ? 64 : 230,
              }}
            >
              {t("views.table.actions")}
            </th>
          )}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
