import React, { forwardRef } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import Link from "next/link";
import PropTypes from "prop-types";
import { useTranslation } from "next-i18next";

const LoginSelector = ({ onChange, variant = "primary", isLogged = false }) => {
  const { t } = useTranslation();
  return (
    <Dropdown align="end" className="me-2" onSelect={onChange}>
      <Dropdown.Toggle
        as={ToggleButton}
        variant={variant}
        id="dropdown-language"
      />

      <Dropdown.Menu aaling="start">
        {!isLogged && (
          <Link href="/login" passHref>
            <Dropdown.Item style={{ paddingRight: "8rem" }} eventKey="/login">
              {t("views.loginSelector.login")}
            </Dropdown.Item>
          </Link>
        )}

        <Dropdown.Item style={{ paddingRight: "8rem" }} eventKey="/checkin">
          {t("views.loginSelector.workerCheckin")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ToggleButton = forwardRef(function Toggle({ onClick, variant }, ref) {
  const getColor = (variant) => {
    switch (variant) {
      case "primary":
        return "#818a91";

      case "secondary":
        return "white";

      case "dark":
        return "#333333";

      default:
        return null;
    }
  };
  const getBorderColor = (variant) => {
    switch (variant) {
      case "primary":
        return "#818a91";

      case "secondary":
        return "white";

      case "dark":
        return "#d0dde6";

      default:
        return null;
    }
  };
  return (
    <div className="d-flex">
      <a
        ref={ref}
        href=""
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="d-flex align-items-center justify-content-center"
        style={{
          height: 32,
          width: 32,
          borderRadius: 6,
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: getBorderColor(variant),
        }}
      >
        <i
          className="bi bi-gear-fill"
          style={{
            fontSize: 17,
            margin: "10px 10px 10px 10px",
            color: getColor(variant),
          }}
        />
      </a>
    </div>
  );
});

LoginSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default LoginSelector;
