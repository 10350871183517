import { useEffect, useState } from "react";

import Image from "next/image";
import { Storage } from "aws-amplify";

const S3Image = ({ imageKey, ...props }) => {
  const [signedUrl, setSignedUrl] = useState("");
  useEffect(() => {
    !!imageKey &&
      Storage.get(imageKey).then((res) => {
        setSignedUrl(res);
      })
      return () => {
        setSignedUrl("")
      }
    
  }, [imageKey]);
  return (
    <Image
      src={signedUrl || "/user.svg"}
      {...props}
      alt="s3 image"
    />
  );
};

export default S3Image;
