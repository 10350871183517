import Header from "./Header";
import Loading from "./Loading";
import React from "react";
import Sidebar from "./Sidebar";
import routes from "../routes";
import styles from "./AdminLayout.module.scss";
import { useAuth } from "../src/context/auth";
import { useRouter } from "next/router";

const AdminLayout = ({ children }) => {
  const router = useRouter();
  const { userData, loading, logout } = useAuth();

  const handleChangeLang = (language) => {
    router.push(
      {
        route: router.pathname,
        query: router.query,
      },
      router.asPath,
      { locale: language }
    );
  };

  const clearStorage = () => {
    localStorage.removeItem("line_job_workers");
    localStorage.removeItem("selectedJobLine");
    localStorage.removeItem("selectedLocation");
    localStorage.removeItem("selectedJob");
  };

  const onSelectLoginType = (path) => {
    if (path === "/checkin") {
      clearStorage();
      logout({ redirectToCheckin: true });
    } else {
      router.push(path);
    }
  };

  const actualRoute = routes.find((route) => route.path === router.route) || {};

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Sidebar
          routes={routes}
          header={
            <Header
              actualRoute={actualRoute}
              handleSignOut={() => {
                clearStorage();
                logout();
              }}
              handleChangeLang={handleChangeLang}
              user={userData}
              handleSelectLogin={onSelectLoginType}
            />
          }
          content={
            loading ? (
              <Loading />
            ) : (
              <div className={styles.formContent}>{children}</div>
            )
          }
        />
      </div>
    </div>
  );
};

export default AdminLayout;
