import { createContext, useContext, useEffect } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import {
  LIST_ALL_POSITIONS,
  GET_WORKER_DATA_BY_ID,
} from "../../graphql/workers.graphql";
import {
  CHECK_IF_HARD_START_EXIST,
  SET_START_STOP,
  GET_TIMELOG_BY_WORKER_LINE_JOB_POSITION,
  UPDATE_TIMELOG,
  UPDATE_TIMELOG_CALC,
  GET_TIMELOG_BY_JOB_POSITION,
  GET_TIMELOG_BY_WORKER_POSITION_RANGE,
  GET_TIMELOG_BY_JOB_COUNT,
} from "../../graphql/checkin.graphql";
import { GET_LINE_JOB_BY_ID } from "../../graphql/jobs.graphql";
import { GET_SPECIAL_RATE_BY_PARAMS } from "../../graphql/special-rates.graphql";
import { dateToString, getLocaleString } from "../../utils/date-utils";
import { useTranslation } from "next-i18next";
import { dataForCheckin } from "../../utils/rate-utils";

const TimelogsContext = createContext({});

export const TimelogsProvider = ({ children }) => {
  const { t } = useTranslation();

  const { data: positions } = useQuery(LIST_ALL_POSITIONS, {
    fetchPolicy: "network-only",
  });

  const [checkIfHardStartExist] = useLazyQuery(CHECK_IF_HARD_START_EXIST, {
    fetchPolicy: "network-only", // Used for first execution
  });
  const [getLineLeaderTimelog] = useLazyQuery(
    GET_TIMELOG_BY_WORKER_LINE_JOB_POSITION,
    {
      fetchPolicy: "network-only", // Used for first execution
    }
  );
  const [getLineLeaderTimelogs] = useLazyQuery(GET_TIMELOG_BY_JOB_POSITION, {
    fetchPolicy: "network-only", // Used for first execution
  });
  const [getSameLineLeaderTimelogs] = useLazyQuery(
    GET_TIMELOG_BY_WORKER_POSITION_RANGE,
    {
      fetchPolicy: "network-only", // Used for first execution
    }
  );
  const [getTimelogByJobCount] = useLazyQuery(GET_TIMELOG_BY_JOB_COUNT, {
    fetchPolicy: "network-only", // Used for first execution
  });

  const [loadWorker] = useLazyQuery(GET_WORKER_DATA_BY_ID, {
    fetchPolicy: "network-only",
  });

  const [getJobData] = useLazyQuery(GET_LINE_JOB_BY_ID, {
    fetchPolicy: "network-only",
  });

  const [recoverSpecialRate] = useLazyQuery(GET_SPECIAL_RATE_BY_PARAMS, {
    fetchPolicy: "network-only",
  });

  const [setStartStop] = useMutation(SET_START_STOP);
  const [updateLineLeaderTimelog] = useMutation(UPDATE_TIMELOG);
  const [updateLineLeaderTimelogCalc] = useMutation(UPDATE_TIMELOG_CALC);

  // Line leaders timelog generation
  // create the line leader timelog or update it if the stop_time changed
  const handleLineLeaderTimelog = async (
    prevJob,
    currentJob,
    reassign,
    checkout_reason = null,
    checkout_comment = null
  ) => {
    const lineLeaderPosition = positions.position.filter(
      (p) => p.comment === "Line Leader"
    );

    const job = currentJob || prevJob;
    const lunchTime = (job.lunch_time * 60 * 1000) || 0;

    if (!lineLeaderPosition.length) {
      toast.error(t("views.dashboard.leader.lineLeaderPositionNeeded"));
      throw new Error();
    }

    const userWorker = prevJob.user.worker;
    // No worker is attached to the user
    if (!userWorker || userWorker.deleted_at != null) {
      toast.error(t("views.dashboard.leader.workerNeeded"));
      throw new Error();
    }

    const lineLeaderId = lineLeaderPosition[0]?.id;

    try {
      // If this job has other line leader timelog/s it should
      // search for the latest stop time to use it as start of the timelog
      const existingLineLeaderTimelogs = await getLineLeaderTimelogs({
        variables: {
          job_id: job.id,
          position_id: lineLeaderId,
          worker_id: userWorker.id,
        },
      });

      const existingTimelogs = existingLineLeaderTimelogs.data.timelog;
      const startTime = existingTimelogs.length
        ? existingTimelogs[0].stop_time
        : prevJob.job_start; // CHECK: why not job.job_start

      const stop = new Date();
      const stopTime = reassign ? getLocaleString(stop) : job.stop_time;

      // Search if exist a hardstart of that worker that day
      const responseHardStart = await checkIfHardStartExist({
        variables: {
          worker_id: userWorker.id,
          date: dateToString(new Date()),
        },
      });

      const isHardStartedAlready =
        !!responseHardStart?.data.timelog_aggregate?.aggregate.total;

      // Load worker data for rate calculus
      const workerResponse = await loadWorker({
        variables: {
          id: userWorker.id,
        },
      });

      let dataWorker = workerResponse?.data?.worker[0] || {};

      // Load job data for rate calculus
      const jobResponse = await getJobData({
        variables: {
          id: job?.id,
        },
      });

      const jobData = jobResponse?.data?.line_job_by_pk;

      // Load special rates for rate calculus
      const specialRateResponse = await recoverSpecialRate({
        variables: {
          where: {
            deleted_at: { _is_null: true },
            agency_id: { _eq: dataWorker?.agency?.id },
          },
        },
      });

      const specialRates = specialRateResponse?.data?.special_rate || [];

      const { rate, markup } = dataForCheckin(
        dataWorker,
        jobData,
        {
          position: { value: lineLeaderId, positionCode: "LINE_LEADER" },
        },
        specialRates
      );

      // Do nothing if the job.state == "STARTED"
      if (job.state === "FINISHED" || reassign) {
        const startC = new Date(startTime);
        const stopC = new Date(stopTime);
        let createTimelog = true;
        if (reassign) {
          const timelogByJobCount = await getTimelogByJobCount({
            variables: {
              line_job_id: job.id,
            },
          });
          createTimelog =
            timelogByJobCount.data?.timelog_aggregate?.aggregate.total !== 0;
        }

        if (createTimelog) {
          const auxTime = stopC.getTime() - startC.getTime();
          const calculatedTime = auxTime >= lunchTime ? auxTime - lunchTime : 0;

          // Create timelog and check the distribution
          // If there's already a hard start this new timelog should be a soft start
          await setStartStop({
            variables: {
              worker_id: userWorker.id,
              position_id: lineLeaderPosition[0].id,
              shift: job.shift,
              line_id: prevJob.line_id,
              date: dateToString(startC),
              start_type: isHardStartedAlready ? "SOFT START" : "HARD START",
              start_time: startTime,
              stop_type: "SOFT STOP",
              stop_time: stopTime,
              line_job_id: job.id,
              rate,
              markup,
              calc_info: "{}",
              calc_time: calculatedTime,
              calc_cost: calculateCost(calculatedTime, rate, markup, job.shift),
              calc_hours: (calculatedTime / 1000 / 60 / 60).toFixed(2),
              lunch_time: lunchTime / 1000 / 60,
              state: "APPROVED",
              checkout_reason,
              checkout_comment,
            },
          });

          await checkLineLeaderOverlaps(
            userWorker.id,
            lineLeaderPosition[0].id,
            startTime,
            stopTime,
            rate,
            markup
          );
        }
      } else if (job.state == "UPDATE") {
        const prevTime = new Date(prevJob.stop_time);
        const currentTime = new Date(job.stop_time);
        const startC = new Date(startTime);
        const stopC = new Date(job.stop_time);
        // Check if the end time changed
        // TODO: compare using timemillis
        if (
          getLocaleString(prevTime) !== getLocaleString(currentTime) ||
          prevJob.lunch_time !== job.lunch_time ||
          prevJob.shift !== job.shift
        ) {
          console.log("Stop time changed, timelogs must be updated");
          // Search for the timelog to update
          const existingLineLeaderTimelog = await getLineLeaderTimelog({
            variables: {
              worker_id: userWorker.id,
              line_job_id: job.id,
              position_id: lineLeaderPosition[0].id,
              date: dateToString(startC),
            },
          });
          const lineLeaderTimelog = existingLineLeaderTimelog.data.timelog[0];

          // Backwards compatibility, some previous jobs dont have line leader timelogs
          if (lineLeaderTimelog) {
            console.log("Line leader timelog exists");
            const auxTime = stopC.getTime() - startC.getTime();
            const calculatedTime = auxTime >= lunchTime ? auxTime - lunchTime : 0;

            // In this case the shift and rate must be updated
            // it isn't controlled by the postgres function
            await updateLineLeaderTimelog({
              variables: {
                id: lineLeaderTimelog.id,
                stop_time: job.stop_time,
                calc_info: "{}",
                calc_time: calculatedTime,
                calc_cost: calculateCost(calculatedTime, rate, markup, job.shift),
                calc_hours: (calculatedTime / 1000 / 60 / 60).toFixed(2),
                lunch_time: lunchTime / 1000 / 60,
                rate: job.shift === 1 ? rate + 0.5 : rate,
                shift: job.shift,
              },
            });

            await checkLineLeaderOverlaps(
              userWorker.id,
              lineLeaderPosition[0].id,
              startTime,
              job.stop_time,
              rate,
              markup
            );
          }
        }
      }
    } catch (e) {
      console.log(e);
      toast.error(t("general.somethingWrong"));
      throw new Error(e);
    }
  };

  const checkLineLeaderOverlaps = async (
    workerId,
    lineLeaderPositionId,
    startTime,
    stopTime,
    rate,
    markup
  ) => {
    console.log("Checking line leader timelogs overlaps");
    // Check if an overlap between timelogs exist
    const existingLineLeaderTimelogs = await getSameLineLeaderTimelogs({
      variables: {
        worker_id: workerId,
        position_id: lineLeaderPositionId,
        start_time: startTime,
        stop_time: stopTime,
      },
    });

    const timelogs = existingLineLeaderTimelogs?.data?.timelog || [];

    if (timelogs.length > 1) {
      console.log("More than one line leader timelogs in the range");

      const timesArr = [];
      timelogs.forEach((timelog) => {
        const start = new Date(timelog.start_time);
        const stop = new Date(timelog.stop_time);
        timesArr.push(start.getTime());
        timesArr.push(stop.getTime());
      });
      const orderedArr = timesArr.sort((a, b) => a - b);
      const rangesArr = [];
      for (let i = 0; i < orderedArr.length - 1; i++) {
        if (i == 0) rangesArr.push([orderedArr[i], orderedArr[i + 1]]);
        else rangesArr.push([orderedArr[i] + 1, orderedArr[i + 1]]);
      }

      const rangesSum = {};

      rangesArr.forEach((value, index) => {
        let sum = 0;
        let tlogs = [];
        timelogs.forEach((timelog) => {
          const start = new Date(timelog.start_time);
          const stop = new Date(timelog.stop_time);
          const intersect =
            (start.getTime() >= value[0] && stop.getTime() < value[1]) ||
            (start.getTime() < value[1] && stop.getTime() >= value[0]);
          if (intersect) {
            sum++;
            tlogs.push(timelog.id);
          }
        });
        rangesSum[index] = {
          range: value,
          diff: value[1] - value[0],
          sum,
          timelogs: tlogs,
        };
      });

      await Promise.all(
        timelogs.map(async (timelog) => {
          const start = new Date(timelog.start_time);
          const stop = new Date(timelog.stop_time);
          let info = { ranges: [] };
          let totalTime = stop.getTime() - start.getTime();
          Object.keys(rangesSum).forEach((key) => {
            const intersect = rangesSum[key].timelogs.filter(
              (t) => t === timelog.id
            );
            if (intersect.length) {
              totalTime =
                totalTime -
                rangesSum[key].diff +
                rangesSum[key].diff / rangesSum[key].sum;
            }
            info.diff = stop.getTime() - start.getTime();
            info.count = timelogs.length;
            // Save related timelgos IDs
            info.related_ids = timelogs.map((t) => t.id);
            info.ranges.push(rangesSum[key]);
          });

          const lunchTime = (timelog.lunch_time * 60 * 1000) || 0;
          const calculatedTime = totalTime >= lunchTime ? totalTime - lunchTime : 0;

          return await updateLineLeaderTimelogCalc({
            variables: {
              id: timelog.id,
              calc_info: info,
              calc_time: calculatedTime,
              calc_cost: calculateCost(calculatedTime, rate, markup, timelog.line_job.shift),
              calc_hours: (calculatedTime / 1000 / 60 / 60).toFixed(2),
            },
          });
        })
      ).catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    console.log("TimelogsProvider");
  }, []);

  return (
    <TimelogsContext.Provider value={{ handleLineLeaderTimelog }}>
      {children}
    </TimelogsContext.Provider>
  );
};

const calculateCost = (time, rate, markup, shift) => {
  let finalRate = shift === 1 ? rate + 0.5 : rate;
  const markupRate = finalRate + (finalRate * markup) / 100;
  const hours = (time / 1000 / 60 / 60).toFixed(2);
  return (hours * markupRate).toFixed(2);
};

export const useTimelogs = () => useContext(TimelogsContext);
