import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { get } from "lodash";
import { useTranslation } from "next-i18next";

const GenericViewModal = ({ open, fields=[], data = {}, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal show={open} onHide={onClose} centered>
      <Modal.Header closeButton>
        <h1 className="mb-0 fw-bold">{t("general.details")}</h1>
      </Modal.Header>
      <Modal.Body className="p-0">
        {fields.map(
          (
            { Header: title, accessor, transformData, displayInModal },
            index
          ) => {
            let fieldData = get(data, accessor, "");

            const renderData = (answer) =>
              !!transformData ? transformData(answer, data) : answer;

            if (!!displayInModal) {
              return (
                <React.Fragment key={index}>
                  {displayInModal(fieldData, data)}
                </React.Fragment>
              );
            } else if (
              data.subRow &&
              !Array.isArray(fieldData) &&
              !renderData(fieldData)
            ) {
              return <React.Fragment key={index} />;
            } else if (Array.isArray(fieldData)) {
              return (
                <div
                  key={index}
                  className="d-flex flex-column justify-content-center"
                  style={{
                    minHeight: 50,
                    padding: "13px 32px",
                    borderBottom: "solid 1px #e6e9ed",
                  }}
                >
                  <div className="d-flex flex-row align-items-start">
                    <div className="mb-0 me-3 fw-bold">{`${title}:`}</div>
                    <div>
                      {fieldData.length ? fieldData.map((answer, index) => (
                        <p className="mb-0" key={index}>
                          {renderData(answer)}
                        </p>
                      )) : "-"}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  className="d-flex flex-column justify-content-center"
                  style={{
                    minHeight: 50,
                    padding: "13px 32px",
                    borderBottom: "solid 1px #e6e9ed",
                  }}
                >
                  <div className="d-flex flex-row align-items-center">
                    <div className="mb-0 me-3 fw-bold">{`${title}:`}</div>
                    <div>{ renderData(fieldData) ? renderData(fieldData) : "-"}</div>
                  </div>
                </div>
              );
            }
          }
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center w-100">
          <Button variant="primary" onClick={onClose}>
            {t("general.close").toUpperCase()}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GenericViewModal;
