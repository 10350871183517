export const getOptions = (
  labelKey,
  data = [],
  loading,
  error,
  extraValues = []
) =>
  loading || error
    ? []
    : data.map((option) => ({
        label: Array.isArray(labelKey)
          ? labelKey.map((key) => option[key]).join(" ")
          : option[labelKey],
        value: option.id || option.value,
        ...extraValues.reduce(
          (result, key) => ({ ...result, [key]: option[key] }),
          {}
        ),
      }));

export const getPaginationOptions = (pageCount, index, embedded = false) => {
  const PAG_OPTIONS_LIMIT = 3 + (embedded ? 1 : 0);
  let paginationOptions = Array.from(Array(pageCount).keys()).map((option) => {
    if (option === 0) {
      return option;
    } else if (option === pageCount - 1) {
      return option;
    } else {
      const realValue = option + 1;
      const distance = Math.abs(realValue - index);
      if (distance < PAG_OPTIONS_LIMIT) {
        return option;
      } else {
        return realValue < index ? "first_spacer" : "second_spacer";
      }
    }
  });

  const firstSpacer = paginationOptions.findIndex(
    (option) => option === "first_spacer"
  );

  if (firstSpacer >= 0) {
    paginationOptions = paginationOptions.filter(
      (option) => option !== "first_spacer"
    );
    paginationOptions.splice(1, 0, "first_spacer");
  }

  const secondSpacer = paginationOptions.findIndex(
    (option) => option === "second_spacer"
  );

  if (secondSpacer >= 0) {
    paginationOptions = paginationOptions.filter(
      (option) => option !== "second_spacer"
    );
    paginationOptions.splice(paginationOptions.length - 1, 0, "second_spacer");
  }

  return paginationOptions;
};

export const getOptionItem = (labelKey, data = {}) => {
  return !!data
    ? {
        label: data[labelKey] || data.label,
        value: data.id || data.value,
      }
    : null;
};
