import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { useTranslation } from "next-i18next";

const FiltersButton = ({ onChange, size, ...props }) => {
  const {t} = useTranslation();
    return (
    <Button
      variant="secondary"
      className={`ms-sm-3`}
      style={{
        marginLeft: size >= 480 && 12,
        height: 42,
        display: "flex",
        alignItems: "center",
      }}
      onClick={onChange}
      {...props}
    >
      <i
        className={`bi bi-filter`}
        style={{
          fontSize: 20,
          marginRight: 8,
          paddingTop: 4,
        }}
      />
      {t("views.table.filters")}
    </Button>
  );
};

FiltersButton.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default FiltersButton;
