import React, { useEffect } from "react";
import { useTranslation } from "next-i18next";
import Modal from "react-bootstrap/Modal";
import Table from "./Table";
import { useMutation, useQuery } from "@apollo/client";
import { GET_FILTERS, DELETE_FILTER } from "../../graphql/filter.graphql";
import { useAuth } from "../../src/context/auth";
import { toast } from "react-toastify";

const FilterListModal = ({ open, toggleModal, onApply, view }) => {
  const { t } = useTranslation();

  const {
    userData: { id: userId },
  } = useAuth();

  const {
    data: netData,
    loading,
    error,
    refetch,
  } = useQuery(GET_FILTERS, {
    variables: {
      where: {
        _and: [
          { user_id: { _eq: userId } },
          { view: { _eq: view } },
          { deleted_at: { _is_null: true } },
        ],
      },
      order: {},
    },
  });

  const [deleteFilter] = useMutation(DELETE_FILTER);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "filter_name",
      },
    ],
    []
  );

  const actions = React.useMemo(
    () => [
      {
        name: t("general.apply"),
        icon: "bi-arrow-down",
        onClick: (row) => {
          const filter = JSON.parse(row.filter_data);
          if (!!filter.date && row.use_current_date) {
            filter.date =
              typeof filter.date === "object"
                ? { startDate: new Date(), endDate: new Date() }
                : new Date();
          }
          onApply(filter);
          toggleModal();
          toast.success(t("general.filters.applied"));
        },
      },
      {
        name: t("general.delete"),
        icon: "bi-trash",
        onClick: (row) => {
          deleteFilter({ variables: { id: row.id } })
            .then(() => {
              toast.success(t("general.filters.deleted"));
              toggleModal();
            })
            .catch((e) => {
              console.log(e);
              toast.error(t("general.somethingWrong"));
            });
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const data = netData?.filter;

  if (error) toast.error(t("general.somethingWrong"));

  return (
    <Modal show={open} onHide={() => toggleModal()} centered>
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-dark"
        style={{
          borderColor: "#2253d4",
        }}
      >
        <h2 className="fw-bold text-light mb-0">
          {t("general.filters.listTitle")}
        </h2>
      </Modal.Header>
      <Modal.Body className="position-relative p-0">
        <Table
          columns={columns}
          data={data}
          actions={actions}
          noHeader
          loading={loading}
        />
      </Modal.Body>
    </Modal>
  );
};

export default FilterListModal;
