import { gql } from "@apollo/client";


export const LIST_SPECIAL_RATES =  gql `
query ListSpecialRates($where: special_rate_bool_exp!, $pageSize: Int, $offset: Int, $order: special_rate_order_by!) {
  special_rate(where: $where, limit: $pageSize, offset: $offset, order_by: [$order]) {
    differential
    markup
    agency {
      name
      id
    }
    special_rate_locations {
      location {
        name
        deleted_at
      }
    }
    special_rate_positions {
      position {
        code
        comment
        comment_es
        deleted_at
      }
    }
    deleted_at
    id
  }
  special_rate_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
`;

export const ADD_SPECIAL_RATE = gql`
mutation AddSpecialRate($object: special_rate_insert_input!) {
  insert_special_rate_one(object: $object) {
    id
  }
}
`;

export const GET_SPECIAL_RATE_BY_PARAMS = gql`
query GetSpecialRateByParams($where: special_rate_bool_exp!) {
  special_rate(where: $where) {
    id
    agency_id
    differential
    markup
    agency {
      name
      id
    }
    special_rate_locations {
      location_id
      location {
        id
        name
        deleted_at
      }
    }
    special_rate_positions {
      position_id
      position {
        id
        code
        comment
        comment_es
        deleted_at
      }
    }
    deleted_at
    id
  }
}
`;


export const UPDATE_SPECIAL_RATE = gql`
mutation EditSpecialRate($id: Int!, $special_rate: special_rate_set_input!, $special_rate_locations: [special_rate_location_insert_input!]!, $special_rate_positions: [special_rate_position_insert_input!]!) {
  update_special_rate_by_pk(pk_columns: {id: $id}, _set: $special_rate) {
    id
  }
  delete_special_rate_location(where: {special_rate: {_eq: $id}}) {
    affected_rows
  }
  delete_special_rate_position(where: {special_rate: {_eq: $id}}) {
    affected_rows
  }
  insert_special_rate_location(objects: $special_rate_locations) {
    affected_rows
  }
  insert_special_rate_position(objects: $special_rate_positions) {
    affected_rows
  }
}
`;


export const DELETE_SPECIAL_RATES = gql`
mutation DeleteSpecialRate($id: Int!) {
  update_special_rate_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
    deleted_at
  }
}
`;