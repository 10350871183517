import { gql } from "@apollo/client";

export const GET_LINES_BY_LOCATION = gql`
  query GetLinesByLocation($location_id: Int!) {
    line(
      where: {
        deleted_at: { _is_null: true }
        location_id: { _eq: $location_id }
      }
    ) {
      id
      description
      deleted_at
      location_id
      number
    }
  }
`;

export const LINES_JOB_BY_LOCATION = gql`
  query GetLinesJobByLocation($location_id: Int!, $date: date!) {
    line_job(
      where: {
        deleted_at: { _is_null: true }
        line: { location_id: { _eq: $location_id } }
        state: { _eq: STARTED }
        date: { _eq: $date }
      }
    ) {
      code
      customer_id
      date
      deleted_at
      description
      id
      shift
      item {
        description
        number
      }
      item_id
      item_material_price
      item_sales_price
      items_qty
      leader_id
      start_time
      stop_time
      customer {
        name
        id
        location_id
      }
      line {
        id
        number
        location {
          id
        }
      }
    }
  }
`;

export const GET_TIMELOG_TO_STOP_BY_WORKER = gql`
  query GetTimelogToStopByWorker($worker_id: Int!) {
    timelog(
      where: {
        stop_time: { _is_null: true }
        deleted_at: { _is_null: true }
        worker_id: { _eq: $worker_id }
      }
    ) {
      date
      deleted_at
      id
      line_id
      line_job_id
      shift
      start_time
      start_type
      state
      stop_time
      stop_type
      worker_id
    }
  }
`;

export const CHECK_IF_HARD_STOP_EXIST = gql`
  query checkIfHardStopExist($worker_id: Int!, $date: date, $line_job_id: Int!) {
    timelog_aggregate(
      where: {
        stop_type: { _eq: "HARD STOP" }
        deleted_at: { _is_null: true }
        worker_id: { _eq: $worker_id }
        date: { _eq: $date }
        line_job_id: { _eq: $line_job_id }
      }
    ) {
      aggregate {
        total: count
      }
    }
  }
`;

export const CHECK_IF_HARD_START_EXIST = gql`
  query checkIfHardStartExist($worker_id: Int!, $date: date) {
    timelog_aggregate(
      where: {
        start_type: { _eq: "HARD START" }
        deleted_at: { _is_null: true }
        worker_id: { _eq: $worker_id }
        date: { _eq: $date }
      }
    ) {
      aggregate {
        total: count
      }
    }
  }
`;

export const SET_HARD_START = gql`
  mutation setHardStart(
    $worker_id: Int!
    $position_id: Int!
    $line_id: Int!
    $line_job_id: Int!
    $shift: Int!
    $start_time: timestamp!
    $date: date!
    $rate: float8!
    $markup: float8!
  ) {
    update_line_job_by_pk(
      pk_columns: { id: $line_job_id }
      _inc: { workers_qty: 1, workers_active: 1 }
    ) {
      id
    }
    insert_timelog_one(
      object: {
        worker_id: $worker_id
        position_id: $position_id
        line_id: $line_id
        shift: $shift
        date: $date
        start_type: "HARD START"
        line_job_id: $line_job_id
        start_time: $start_time
        rate: $rate
        markup: $markup
        stop_type: ""
      }
    ) {
      id
    }
    insert_line_job_worker(
      objects: { woker_id: $worker_id, line_job_id: $line_job_id }
    ) {
      returning {
        id
        line_job_id
        woker_id
        worker {
          first_name
          last_name
        }
      }
    }
  }
`;


export const SET_SOFT_START = gql`
  mutation setSoftStart(
    $id: Int!
    $from_job: Int!
    $worker_id: Int!
    $position_id: Int!
    $line_id: Int!
    $line_job_id: Int!
    $shift: Int!
    $stop_time: timestamp!
    $date: date!
    $rate: float8!
    $markup: float8!
  ) {
    update_line_job_by_pk(pk_columns: {id: $from_job}, _inc: {workers_active: -1}) {
      id
      workers_active
    }
    update_timelog_by_pk(
      pk_columns: { id: $id }
      _set: { stop_type: "SOFT STOP", stop_time: $stop_time }
    ) {
      id
    }
    insert_timelog_one(
      object: {
        worker_id: $worker_id
        line_id: $line_id
        position_id: $position_id
        shift: $shift
        date: $date
        start_type: "SOFT START"
        line_job_id: $line_job_id
        rate: $rate
        markup: $markup
        start_time: $stop_time
        stop_type: ""
      }
    ) {
      id
    }
    update_line_job(where: { id: {_eq: $line_job_id }},  _inc: {workers_active: 1}) {
      returning {
        id
        workers_active
      }
    }
  }
`;

export const SET_SOFT_START_WITHOUT_UPDATE = gql`
  mutation setSoftStartWithoutUpdate(
    $worker_id: Int!
    $position_id: Int!
    $line_id: Int!
    $line_job_id: Int!
    $shift: Int!
    $start_time: timestamp!
    $date: date!
    $rate: float8!
    $markup: float8!
  ) {
    update_line_job_by_pk(
      pk_columns: { id: $line_job_id }
      _inc: { workers_qty: 1, workers_active: 1 }
    ) {
      id
    }
    insert_timelog_one(
      object: {
        worker_id: $worker_id
        line_id: $line_id
        position_id: $position_id
        shift: $shift
        date: $date
        start_type: "SOFT START"
        line_job_id: $line_job_id
        rate: $rate
        markup: $markup
        start_time: $start_time
        stop_type: ""
      }
    ) {
      id
    }
  }
`;

export const SET_HARD_STOP = gql`
  mutation setHardStop(
    $id: Int!
    $reason: checkout_reason_enum!
    $description: String!
    $stop_time: timestamp!
    $from_job: Int!
  ) {
    update_line_job_by_pk(pk_columns: {id: $from_job}, _inc: {workers_active: -1}) {
      id
      workers_active
    }
    update_timelog_by_pk(
      pk_columns: { id: $id }
      _set: {
        stop_type: "HARD STOP"
        stop_time: $stop_time
        checkout_reason: $reason
        checkout_comment: $description
      }
    ) {
      id
    }
  }
`;

export const GET_TIMELOGS_BY_LINE_TODAY = gql`
  query GetTimelogOfTodayByLine($line_job_id: Int!, $date: date!) {
    timelog(
      order_by: { id: desc }
      where: { date: { _eq: $date }, line_job_id: { _eq: $line_job_id } }
    ) {
      start_type
      stop_type
      worker {
        first_name
        last_name
        position {
          comment_es
          comment
          id
        }
        id
      }
      id
    }
    timelog_aggregate(distinct_on: worker_id) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;


export const GET_LAST_WORKER_TIMELOG =  gql`
  query GetLastWorkerTimelog($where: timelog_bool_exp!) {
    timelog(where: $where, limit: 1, order_by: {id: desc}) {
      id
      start_type
      stop_type
      date
      line_job_id
      line_id
      position {
        comment
        comment_es
        id
      }
      line {
        number
      }
    }
  }
`;

export const GET_WORKER_BY_LINE_JOB = gql`
  query GetWorkersByLineJob($line_job_id: Int!) {
      line_job_worker(where: {line_job_id: {_eq: $line_job_id}}, order_by: {worker: {timelogs_aggregate: {max: {id: asc}}}}) {
        worker {
        id
        first_name
        last_name
        unique_access_id
        position {
          comment
          comment_es
        }
        agency {
          name
          id
        }
        timelogs(
          where: {  deleted_at: { _is_null: true }, }
          limit: 1
          order_by: { start_time: desc }
        ) {
          id
          start_type
          stop_type
          date
          line_job_id
          line_id
          position {
            comment
            comment_es
            id
          }
          line {
            number
          }
        }
        picture
      }
    }
  }
`;

export const COUNT_WORKER_AT_LINE_JOB = gql`
  query countWorkerAtLineJob($worker_id: Int!, $line_job_id: Int!) {
    line_job_worker_aggregate(
      where: {
        line_job_id: { _eq: $line_job_id }
        woker_id: { _eq: $worker_id }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const INSERT_LINE_JOB_WORKER = gql`
  mutation moveToline($worker_id: Int!, $line_job_id: Int!) {
    update_line_job_by_pk(
      pk_columns: { id: $line_job_id }
      _inc: { workers_qty: 1 }
    ) {
      id
    }
    insert_line_job_worker(
      objects: { woker_id: $worker_id, line_job_id: $line_job_id }
    ) {
      returning {
        id
        line_job_id
        woker_id
        worker {
          first_name
          last_name
        }
      }
    }
  }
`;

export const LIST_ALL_REASONS = gql`
  query getReasons {
    checkout_reason (order_by: {comment: asc}) {
      comment
      value
    }
  }
`;

export const GET_FULL_LINES_WORKER_INFO = gql`
  query GetFullLinesWorkerInfo($worker_id: Int!, $date: date!) {
    timelog(where: {deleted_at: {_is_null: true}, date: {_eq: $date}, worker_id: {_eq: $worker_id}}, order_by: {start_time: asc}) {
      id
      line_id
      line_job_id
      line {
        number
      }
      position {
        comment
        comment_es
        id
      }
      shift
      start_time
      start_type
      state
      stop_time
      stop_type
    }
  }
`;

export const GET_TIMELOG_BY_WORKER_LINE_JOB_POSITION = gql`
  query GetTimelogByWorkerLineJobPosition($worker_id: Int!, $line_job_id: Int!, $position_id: Int!) {
    timelog(
      where: {
        deleted_at: { _is_null: true }
        worker_id: { _eq: $worker_id }
        line_job_id : { _eq: $line_job_id }
        position_id: { _eq: $position_id }
      }
    ) {
      date
      id
      line_id
      line_job_id
      worker_id
      position_id
      shift
      start_time
      start_type
      state
      stop_time
      stop_type 
    }
  }
`;

export const GET_TIMELOG_BY_JOB_POSITION = gql`
  query GetTimelogByWorkerPositionRange($job_id: Int!, $position_id: Int!, $worker_id: Int!) {
    timelog(
      where: {
        deleted_at: { _is_null: true }
        line_job_id: { _eq: $job_id }
        position_id: { _eq: $position_id }
        worker_id: { _neq: $worker_id }
        stop_time: { _is_null: false }
      },
      order_by: { stop_time: desc }
    ) {
      date
      id
      line_id
      line_job_id
      worker_id
      position_id
      shift
      start_time
      start_type
      state
      stop_time
      stop_type 
      lunch_time
    }
  }
`;

export const GET_TIMELOG_BY_WORKER_POSITION_RANGE = gql`
  query GetTimelogByWorkerPositionRange($worker_id: Int!, $position_id: Int!, $start_time: timestamp!, $stop_time: timestamp!) {
    timelog(
      where: {
        deleted_at: { _is_null: true }
        worker_id: { _eq: $worker_id }
        position_id: { _eq: $position_id }
        _or: [
          { start_time: { _gte: $start_time, _lte: $stop_time} }
          { stop_time: { _gte: $start_time, _lte: $stop_time} }
        ] 
      }
    ) {
      date
      id
      line_id
      line_job_id
      line_job {
        shift
      }
      worker_id
      position_id
      shift
      start_time
      start_type
      state
      stop_time
      stop_type 
      lunch_time
    }
  }
`;

export const SET_START_STOP = gql`
  mutation setHardStartSoftStop(
    $worker_id: Int!
    $position_id: Int!
    $line_id: Int!
    $line_job_id: Int!
    $shift: Int!
    $start_time: timestamp!
    $stop_time: timestamp!
    $start_type: String!
    $stop_type: String!
    $date: date!
    $rate: float8!
    $markup: float8!
    $calc_time: float8!
    $calc_cost: float8!
    $calc_hours: float8!
    $calc_info: jsonb!
    $state: String!
    $lunch_time: Int
    $checkout_reason: checkout_reason_enum
    $checkout_comment: String!
  ) {
    insert_timelog_one(
      object: {
        worker_id: $worker_id
        position_id: $position_id
        line_id: $line_id
        shift: $shift
        date: $date
        start_type: $start_type
        line_job_id: $line_job_id
        start_time: $start_time
        stop_type: $stop_type
        stop_time: $stop_time
        rate: $rate
        markup: $markup
        calc_time: $calc_time
        calc_cost: $calc_cost
        calc_hours: $calc_hours
        calc_info: $calc_info
        state: $state
        lunch_time: $lunch_time
        checkout_reason: $checkout_reason
        checkout_comment: $checkout_comment
      }
    ) {
      id
    }
    update_line_job_by_pk(pk_columns: {id: $line_job_id}, _inc: {workers_qty: 1}) {
      id
    }
  }
`;

export const UPDATE_TIMELOG = gql`
  mutation updateLineLeaderTimelog(
    $id: Int!
    $stop_time: timestamp!
    $calc_time: float8!
    $calc_hours: float8!
    $calc_cost: float8!
    $calc_info: jsonb!
    $lunch_time: Int,
    $rate: float8!,
    $shift: Int!
  ) {
    update_timelog_by_pk(
      pk_columns: { id: $id }
      _set: {
        stop_type: "SOFT STOP"
        stop_time: $stop_time,
        calc_time: $calc_time,
        calc_hours: $calc_hours,
        calc_cost: $calc_cost,
        calc_info: $calc_info,
        lunch_time: $lunch_time,
        rate: $rate,
        shift: $shift
      }
    ) {
      id
    }
  }
`;

export const UPDATE_TIMELOG_CALC = gql`
  mutation updateLineLeaderTimelog(
    $id: Int!
    $calc_info: jsonb!,
    $calc_time: float8!,
    $calc_cost: float8!,
    $calc_hours: float8!
  ) {
    update_timelog_by_pk(
      pk_columns: { id: $id }
      _set: {
        calc_info: $calc_info
        calc_time: $calc_time
        calc_cost: $calc_cost
        calc_hours: $calc_hours
      }
    ) {
      id
    }
  }
`;


export const GET_TIMELOG_BY_JOB_COUNT = gql`
  query GetTimelogByJobCount($line_job_id: Int!) {
    timelog_aggregate(where: {line_job: {id: {_eq: $line_job_id}}, deleted_at: {_is_null: true}}) {
      aggregate {
        total: count
      }
    }
  }
`;