import { useTranslation } from "next-i18next";
import CheckboxFilter from "../Filters/CheckboxFilter";
import { EVENT_TYPES } from "../../pages/reports/user-activity";

const UserActivityFilters = ({ filters, setFilters }) => {
  const { t } = useTranslation();

  const typeOptions = [
    {
      value: EVENT_TYPES.VIEW_REPORT,
      label: t(`views.reports.user-activity.${EVENT_TYPES.VIEW_REPORT}`),
    },
    {
      value: EVENT_TYPES.LOGIN,
      label: t(`views.reports.user-activity.${EVENT_TYPES.LOGIN}`),
    },
  ];
  const reportOptions = [
    {
      value: "embedded-dashboard",
      label: t("views.sidebar.embedded-dashboard"),
    },
    { value: "workers-skill", label: t("views.sidebar.workers-skill") },
    { value: "workers-list", label: t("views.sidebar.workers-list") },
    { value: "daily-logged", label: t("views.sidebar.daily-logged") },
    { value: "daily-movement", label: t("views.sidebar.daily-movement") },
    { value: "labor-by-line", label: t("views.sidebar.labor-by-line") },
    { value: "daily-by-location", label: t("views.sidebar.daily-by-location") },
    { value: "daily-by-agency", label: t("views.sidebar.daily-by-agency") },
    {
      value: "daily-by-agency-with-prices",
      label: t("views.sidebar.daily-by-agency-with-prices"),
    },
    { value: "weekly-by-agency", label: t("views.sidebar.weekly-by-agency") },
    { value: "expected-labor", label: t("views.sidebar.expected-labor") },
    {
      value: "expected-production",
      label: t("views.sidebar.expected-production"),
    },
    {
      value: "daily-by-customer-item",
      label: t("views.sidebar.daily-by-customer-item"),
    },
    { value: "daily-pandl", label: t("views.sidebar.daily-pandl") },
    { value: "daily-pandl-misc", label: t("views.sidebar.daily-pandl-misc") },
    { value: "comparison-pandl", label: t("views.sidebar.comparison-pandl") },
    {
      value: "overlapped-timelogs",
      label: t("views.sidebar.overlapped-timelogs"),
    },
    { value: "audit", label: t("views.sidebar.audit") },
    { value: "audit-timelogs", label: t("views.sidebar.audit-timelogs") },
    { value: "user-activity", label: t("views.sidebar.user-activity") },
  ];

  return (
    <>
      <CheckboxFilter
        title={t("views.reports.user-activity.type")}
        selectedOptions={filters.types}
        onChange={(types) => setFilters({ types })}
        options={typeOptions}
      />

      <CheckboxFilter
        title={t("views.reports.user-activity.report-name")}
        selectedOptions={filters.report_names}
        onChange={(report_names) => setFilters({ report_names })}
        options={reportOptions}
      />
    </>
  );
};

export default UserActivityFilters;
