import {
  AUTHENTICATED_PAGES,
  AUTHORIZED_PAGES,
  PUBLIC_PAGES,
} from "../../utils/constants";
import { ApolloProvider, useLazyQuery, useMutation } from "@apollo/client";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";

import AdminLayout from "../../components/AdminLayout";
import { Auth } from "aws-amplify";
import { GET_USER_BY_COGNITO_ID } from "../../graphql/users.graphql";
import Loading from "../../components/Loading";
import UnathorizedPage from "../../pages/unauthorized";
import awsconfig from "../../aws-exports";
import { client } from "../../apollo-client";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useIpHook } from "../../utils/ipHook";
import NotAllowed from "../../pages/not-allowed";
import { FiltersProvider } from "./filters";

import { ADD_EVENT } from "../../graphql/events.graphql";
import { EVENT_TYPES } from "../../pages/reports/user-activity";

Auth.configure(awsconfig);
const AuthContext = createContext({});

export const AuthProvider = ({
  children,
  cognitoIdentityServiceProvider,
  cognitoS3ServiceProvider,
}) => {
  const router = useRouter();
  const { t } = useTranslation();

  const ip = useIpHook();

  const poolData = {
    UserPoolId: process.env.NEXT_PUBLIC_AWS_USERS_POOL_ID,
    ClientId: process.env.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
  };

  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userRoles, setUserRoles] = useState([null]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [loading, setLoading] = useState(true);

  const [, { data, loading: loadingUser, error, refetch }] = useLazyQuery(
    GET_USER_BY_COGNITO_ID
  );
  const [ addEvent ] = useMutation(ADD_EVENT, {});

  const publicPage = PUBLIC_PAGES.includes(router.pathname);

  async function fetchData() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      setUser(cognitoUser);
      const localUser = localStorage.getItem("user");
      if (!localUser || localUser === null) {
        const response = await refetch({ cognitoId: cognitoUser.username });
        const userObject = response?.data?.user[0];
        setUserData(userObject);
        setRolesAndPermissions(userObject);
      } else {
        const userObject = JSON.parse(localUser);
        setUserData(userObject);
        setRolesAndPermissions(userObject);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  async function sendLoginEvent() {
    await addEvent({
      variables: {
        object: {
          type: EVENT_TYPES.LOGIN,
          user_id: data?.user[0]?.id,
        },
      },
    });
  }

  useEffect(() => {
    if (error) {
      toast.error(t("general.somethingWrong"));
    }
    const localUser = localStorage.getItem("user");
    if (!loadingUser && (!localUser || localUser === null) && !!data) {
      const userObject = data?.user[0];
      if (userObject) {
        localStorage.setItem("user", JSON.stringify(userObject));
        setUserData(userObject);
        setRolesAndPermissions(userObject);
        sendLoginEvent();
      }
    }
  }, [data, loadingUser, error]);

  useEffect(() => {
    fetchData();
  }, [router.pathname]);

  const login = async () => {
    console.log("login");
  };

  /**
   * Get roles and permissions
   * @param {} userObject
   */
  const setRolesAndPermissions = (userObject) => {
    if (userObject.user_rols) {
      const rolData = userObject.user_rols.map((rol) => rol.rol);
      const permissionsData = rolData
        .map((rol) =>
          rol.rol_permissions.map((permission) => permission.permission)
        )
        .flat();
      setUserRoles(rolData);
      setUserPermissions(permissionsData);
    }
  };

  const logout = (options) => {
    try {
      localStorage.removeItem("user");
      Auth.signOut().then(
        () => {
          options?.redirectToCheckin
            ? router.replace("/checkin")
            : router.replace("/login");
        },
        () => {
          toast.error("Logout error");
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Verify if a user has a specific type of permission
   * @param {*} permission
   * @returns
   */
  const hasPermission = (permission) => {
    const permissionFilter = userPermissions.filter(
      (p) => p.code === permission
    );
    if (permissionFilter.length) {
      return true;
    }
    return false;
  };

  /**
   * Verify if a user has a specific type of role
   * @param {*} role
   * @return
   */
  const hasRoleType = (rolType) => {
    return !!userRoles.find((role) => role?.rol_type === rolType);
  };

  // Current supported mask
  const indexByMask = {
    8: 1,
    16: 2,
    24: 3,
    32: 4,
  };

  // We only check IPs for line leaders
  if (
    userRoles.find(
      (role) =>
        role?.rol_type === "LINE_LEADER" &&
        ip &&
        router.pathname !== "/login" &&
        !loadingUser &&
        !!userData?.agency
    )
  ) {
    // Check valid IPs of every location associated with the user's agency
    const allowedIp = userData?.agency?.locations.find((l) => {
      return l.location?.allowed_ips?.find((locationIp) => {
        const [allowedIP, mask] = locationIp.split("-");

        if (!mask) {
          return allowedIP === ip;
        } else {
          // If there its a mask we should check a portion of given IPs
          const maskIndex = indexByMask[mask];

          const allowedDomain = allowedIP
            .split(".")
            .filter((segment, index) => (index < maskIndex ? segment : ""))
            .join(".");

          const ipToCheck = ip
            .split(".")
            .filter((segment, index) => (index < maskIndex ? segment : ""))
            .join(".");

          return allowedDomain === ipToCheck;
        }
      });
    });

    if (!allowedIp) {
      return <NotAllowed logout={logout} />;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        cognitoIdentityServiceProvider,
        cognitoS3ServiceProvider,
        poolData,
        updateUserData: fetchData,
        login,
        isLoading: !!loading,
        logout,
        isPublic: publicPage,
        userData,
        userRoles,
        userPermissions,
        hasPermission,
        hasRoleType,
      }}
    >
      <ApolloProvider client={client}>
        <FiltersProvider>
          {loading ? (
            <Loading />
          ) : !publicPage ? (
            <ProtectedRoute
              isAuthenticated={!!user}
              pathname={router.pathname}
              hasPermission={hasPermission}
            >
              {children}
            </ProtectedRoute>
          ) : (
            <PublicRoute>{children}</PublicRoute>
          )}
        </FiltersProvider>
      </ApolloProvider>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

const ProtectedRoute = ({
  isAuthenticated,
  hasPermission,
  pathname,
  children,
}) => {
  if (isAuthenticated) {
    if (AUTHENTICATED_PAGES.includes(pathname)) {
      // Only checks that user is authenticated
      return <AdminLayout>{children}</AdminLayout>;
    } else {
      // Checks if the user has specific permissions
      const permission = AUTHORIZED_PAGES[pathname];
      if (hasPermission(permission))
        return <AdminLayout>{children}</AdminLayout>;
    }
  }
  return <UnathorizedPage />;
};

const PublicRoute = ({ children }) => {
  return children;
};

export { Auth };
