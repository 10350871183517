import clsx from "clsx";
import { useTranslation } from "next-i18next";
import React, { useState } from "react";

import { Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { timestampToDateString } from "../../utils/date-utils";
import styles from "./SearchBox.module.scss";

const DropdownToggle = ({
  title,
  filter,
  label,
  values,
  date = false,
  primaryLabel,
  lightLabel,
  onChange,
  resetOptions,
  clearSelection,
  asInput,
  required,
  containerClass = "",
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  let width = 0;

  if (typeof window !== "undefined") {
    width = window?.innerWidth;
  }
  return (
    <Dropdown
      align="start"
      className={!!containerClass ? containerClass : asInput ? "" : "m-3"}
      show={open}
      onToggle={() => {
        setOpen(false);
        resetOptions();
      }}
    >
      <Dropdown.Toggle
        id="dropdown-basic"
        as={asInput ? CustomInputButton : CustomToggleButton}
        title={title}
        label={label}
        primaryLabel={primaryLabel}
        lightLabel={lightLabel}
        setOpen={setOpen}
        width={width}
        required={required}
        asInput={asInput}
        disabled={disabled}
      />

      <Dropdown.Menu
        aaling="start"
        className="dropdown-menu-center"
        style={{
          borderRadius: 5,
          boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.2)",
          padding: 0,
        }}
        show={open}
      >
        <Dropdown.Item
          eventKey="1"
          className="bg-white text-dark d-flex flex-column align-items-end p-0"
          draggable={"false"}
        >
          {filter}
          <div
            className={clsx(
              "d-flex justify-content-end py-3 px-2 w-100 ",
              date && width > 1380 && "border-start border-secondary",
              width < 1380 && "border-top border-secondary"
            )}
            style={{ maxWidth: 673 }}
          >
            {date && width > 1380 && (
              <p className="mb-0 me-3 p-2">
                {timestampToDateString(values[0]?.startDate, language)}
              </p>
            )}
            {date && width > 1380 && (
              <p className="mb-0 me-4 p-2">
                {timestampToDateString(values[0]?.endDate, language)}
              </p>
            )}
            {!!clearSelection && (
              <Button
                variant="danger"
                onClick={() => {
                  setOpen(false);
                  clearSelection();
                }}
                className="me-1 text-light"
              >
                {t("views.filters.clearSelected").toUpperCase()}
              </Button>
            )}
            <Button
              variant="secondary"
              onClick={() => {
                setOpen(false);
                resetOptions();
              }}
              className="me-1 ms-1"
            >
              {t("general.cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                try {
                  const error = await onChange(values);
                  if (!error) {
                    setOpen(false);
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
              className="ms-1"
            >
              {t("general.apply").toUpperCase()}
            </Button>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownToggle;

const CustomToggleButton = React.forwardRef(function Toggle(
  { onClick, title, label, primaryLabel, setOpen, disabled },
  ref
) {
  return (
    <a
      ref={ref}
      href=""
      onClick={(e) => {
        e.preventDefault();
        if (!disabled) {
          onClick(e);
          setOpen(true);
        }
      }}
      className={clsx(
        "py-1 px-2 d-flex align-items-center justify-content-end",
        styles["toggle-button"],
        disabled && "bg-disabled"
      )}
    >
      <div
        className="d-flex px-2 flex-column align-items-center"
        style={{ width: "90%" }}
      >
        <p className="fw-bold m-0">{title}</p>
        <p
          className={clsx(
            !primaryLabel ? "text-secondary" : "text-primary",
            styles["toggle-button-label"]
          )}
        >
          {label}
        </p>
      </div>

      <i className="bi bi-caret-down-fill" style={{ fontSize: 12 }}></i>
    </a>
  );
});

const CustomInputButton = React.forwardRef(function Toggle(
  { onClick, title, label, lightLabel, setOpen, required, asInput, disabled },
  ref
) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="w-100 d-flex align-items-center">
        <p className={styles["toggle-input-title"]}>{title}</p>
        {!required && (
          <span className={styles.optional}>({t("general.optional")})</span>
        )}
      </div>
      <Button
        variant="light"
        ref={ref}
        href=""
        onClick={(e) => {
          e.preventDefault();
          if (!disabled) {
            onClick(e);
            setOpen(true);
          }
        }}
        className={clsx(
          "ps-3 pe-2 d-flex align-items-center justify-content-end bg-white",
          styles["toggle-input"],
          disabled && "bg-disabled"
        )}
        style={{ maxWidth: asInput ? "100%" : 180 }}
      >
        <div className="d-flex flex-column align-items-start justify-content-between w-100">
          <p
            className="m-0 fw-normal"
            style={{ color: lightLabel ? "#7a7e82" : "" }}
          >
            {label}
          </p>
        </div>

        <i className="bi bi-caret-down-fill" style={{ fontSize: 16 }}></i>
      </Button>
    </div>
  );
});
