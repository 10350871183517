import NumberFormat from "react-number-format";
import styles from "./TextInputWithFormik.module.scss";
import { useTranslation } from "next-i18next";

const NumberInput = ({
  type,
  isValid,
  isInvalid,
  thousandSeparator,
  ...props
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  let borderColor = "#cdcdcd";

  if (isValid) borderColor = "#198754";

  if (isInvalid) borderColor = "#dc3545";

  const isSpanish = language === "es";

  return (
    <NumberFormat
      thousandSeparator={
        typeof thousandSeparator === "boolean"
          ? thousandSeparator
          : isSpanish
          ? "."
          : ","
      }
      decimalSeparator={isSpanish ? "," : "."}
      prefix={type === "currency" ? "$" : ""}
      className={styles.number}
      style={{ borderColor }}
      allowNegative={false}
      isNumericString={true}
      decimalScale={0}
      {...props}
      onChange={(e) => {
        let newValue = e?.target?.value || "";

        newValue = newValue.replaceAll("$", "");

        newValue = isSpanish
          ? newValue.replaceAll(".", "")
          : newValue.replaceAll(",", "");

        if (isSpanish) newValue = newValue.replaceAll(",", ".");

        props.onChange({
          target: {
            name: props.name,
            value: newValue,
          },
        });
      }}
    />
  );
};

export default NumberInput;
