import { AUTHENTICATED_PAGES, PUBLIC_PAGES } from "../../utils/constants";
import React, { useState } from "react";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Image from "next/image";
import Link from "next/link";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./Sidebar.module.scss";
import { useAuth } from "../../src/context/auth";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useWindowSize } from "../../utils/windowHook";
import { useFilters } from "../../src/context/filters";

import { VERSION } from "../../utils/constants";
import { useMutation } from "@apollo/client";
import { ADD_EVENT } from "../../graphql/events.graphql";
import { EVENT_TYPES } from "../../pages/reports/user-activity";

const permissionsMap = {
  list: "view",
  add: "create",
  update: "update",
  delete: "delete",
};

const specialPermissions = {
  "/workers/markup": "workers.update",
  // "/workers/rates": "workers.bulkupdate",
  "/workers/rates": "workers.update",
  "/workers/skills": "workers.skillsbulkupdate",
  "/jobs/approve": "lines.approveworkershours",
  "/jobs/bulk-update": "linejobs.update",
  "/reports/daily-logged": "reports.labordailylogged",
  "/reports/daily-by-location": "reports.dailybylocation",
  "/reports/workers-skill": "reports.workerskills",
  "/reports/daily-movement": "reports.dailymovements",
  "/reports/expected-labor": "reports.laborexpected",
  "/reports/daily-by-customer-item": "reports.dailybyline",
  "/reports/labor-by-line": "reports.laborbyline",
  "/reports/daily-by-agency": "reports.dailybyagency",
  "/reports/daily-by-agency-with-prices": "reports.dailybyagencywithprices",
  "/reports/weekly-by-agency": "reports.weeklybyagency",
  "/reports/workers-list": "reports.workerslist",
  "/reports/expected-production": "reports.productionexpected",
  "/reports/daily-production": "reports.dailypandl",
  "/reports/comparison-pandl": "reports.comparison_pandl",
  "/reports/daily-production-misc": "reports.dailypandlmisc",
  "/reports/overlapped-timelogs": "reports.overlappedtimelogs",
  "/reports/audit": "audit.view",
  "/reports/audit-timelogs": "audit.view",
  "/reports/user-activity": "reports.useractivity",
  "/embedded-dashboard": "reports.powerbi",
};

const getModuleName = (module) => {
  const moduleMap = {
    productionlines: "production_lines",
    linejobs: "job_lines",
    specialrates: "special_rates",
    jobtype: "job_type",
  };
  return moduleMap[module] || module;
};

const Sidebar = ({ routes, header, content }) => {
  const [expanded, setExpanded] = useState(false);
  const { hasPermission } = useAuth();
  const size = useWindowSize();
  const allowedRoutes = routes
    .filter(
      (r) =>
        hasPermission(`${r.name}.view`) ||
        AUTHENTICATED_PAGES.includes(r.path) ||
        PUBLIC_PAGES.includes(r.path) ||
        hasPermission(specialPermissions[r.path])
    )
    .map((route) => ({
      ...route,
      paths: route.paths.filter(
        (p) =>
          hasPermission(`${route.name}.${permissionsMap[p.name]}`) ||
          AUTHENTICATED_PAGES.includes(p.path) ||
          PUBLIC_PAGES.includes(p.path) ||
          hasPermission(specialPermissions[p.path])
      ),
    }));

  const expand = "xl";

  return (
    <>
      <div className={`d-flex flex-column flex-${expand}-row h-100`}>
        <div className="d-flex flex-row">
          <Navbar
            variant="dark"
            bg="dark"
            onToggle={(exp) => {
              setExpanded(exp);
            }}
            expanded={expanded}
            expand={expand}
            className={`d-flex flex-column flex-${expand}-row p-0`}
            style={{ maxWidth: 280 }}
          >
            <Container fluid className="d-flex flex-column h-100">
              <Navbar.Brand
                href="#"
                className={`d-none d-${expand}-block w-100 pt-0 pb-0`}
                style={{ marginRight: 0 }}
              >
                <div
                  role="button"
                  className={`d-none d-${expand}-flex justify-content-center pt-2 pb-2`}
                  style={{
                    borderBottom: "1px solid",
                    borderColor: "rgba(204, 204, 204, 0.25)",
                  }}
                >
                  <Image
                    alt="logo-white"
                    src="/logo-white-vertical.svg"
                    width={80}
                    height={30}
                  />
                </div>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                style={{ height: 48 }}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="start"
                className="bg-dark overflow-auto"
                style={{ maxWidth: 280 }}
              >
                <Offcanvas.Header
                  closeButton
                  closeVariant="white"
                  className="bg-dark"
                >
                  <div
                    className="w-100 d-flex flex-row justify-content-center ms-3 me-3"
                    style={{
                      borderBottom: "1px solid",
                      borderColor: "rgba(204, 204, 204, 0.25)",
                    }}
                  >
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    >
                      <Link passHref href="/dashboard">
                        <div>
                          <div
                            role="button"
                            className="pt-2 pb-2"
                            style={{ height: 48 }}
                          >
                            <Image
                              alt="logo-white"
                              src="/logo-white-vertical.svg"
                              width={80}
                              height={30}
                            />
                          </div>
                        </div>
                      </Link>
                    </Offcanvas.Title>
                  </div>
                </Offcanvas.Header>
                <Offcanvas.Body
                  className="bg-dark d-flex flex-column justify-content-between h-100"
                  style={{ padding: "16px" }}
                >
                  <ItemList routes={allowedRoutes} setExpanded={setExpanded} />
                  <p className={styles.version}>{VERSION}</p>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
          {size.width < 1200 && (
            <div className={`d-block d-${expand}-none w-100`}>{header}</div>
          )}
        </div>
        {size.width < 1200 && (
          <div className={`d-block d-${expand}-none`}>{content}</div>
        )}
        {size.width >= 1200 && (
          <div className={`d-none d-${expand}-flex flex-column w-100`}>
            {header}
            {content}
          </div>
        )}
      </div>
    </>
  );
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
};

export default Sidebar;

const ItemList = ({ routes, setExpanded }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const currentPath = router.asPath;
  const [data, setData] = useState();
  const { userData } = useAuth();

  const { clean } = useFilters();

  const [addEvent] = useMutation(ADD_EVENT, {});

  return (
    <div>
      {routes.map((item, index) => {
        const itemActive = index === data;
        const onClick = (item, index) => {
          if (!item?.paths?.length) {
            if (item.path === "/embedded-dashboard") {
              addEvent({
                variables: {
                  object: {
                    report_name: item.name,
                    type: EVENT_TYPES.VIEW_REPORT,
                    user_id: userData?.id,
                  },
                },
              });
            }
            router.push(item.path);
          } else {
            setData(itemActive ? null : index);
          }
        };
        return (
          (item?.path !== "/reports" ||
            (item?.path === "/reports" && !!item.paths?.length)) && (
            <Card
              key={index}
              className={styles["main-container"]}
              style={{
                backgroundColor: itemActive
                  ? "rgba(255, 255, 255, 0.15)"
                  : "#3665df",
              }}
            >
              <Card.Header className={styles.header}>
                <button
                  type="button"
                  onClick={() => onClick(item, index)}
                  className={clsx("mt-0", styles.module)}
                  style={{
                    backgroundColor:
                      currentPath === item.path && !item?.paths?.length
                        ? "#113eb3"
                        : "transparent",
                  }}
                >
                  {!!item?.paths?.length ? (
                    <i
                      className={
                        itemActive
                          ? "bi bi-chevron-down"
                          : "bi bi-chevron-right"
                      }
                      style={{ paddingRight: 8, height: "100%" }}
                    />
                  ) : (
                    <i
                      className={`bi ${item.icon}`}
                      style={{
                        color: "white",
                        width: 14,
                        height: 14,
                        marginRight: 8,
                      }}
                    />
                  )}
                  {t(`views.sidebar.${getModuleName(item.name)}`)}
                </button>
              </Card.Header>
              {data === index && (
                <div key={index}>
                  <Card.Body className={styles["submodule-container"]}>
                    {item.paths.map(({ name, path }, index2) => (
                      <Link key={index2} passHref href={path}>
                        <a className="text-reset">
                          <h4
                            className={styles.submodule}
                            style={{
                              backgroundColor:
                                currentPath === path ? "#113eb3" : "#3665df",
                            }}
                            onClick={(e) => {
                              if (item?.path === "/reports") {
                                addEvent({
                                  variables: {
                                    object: {
                                      report_name: name,
                                      type: EVENT_TYPES.VIEW_REPORT,
                                      user_id: userData?.id,
                                    },
                                  },
                                });
                              }
                              e.stopPropagation();
                              setExpanded(false);
                              setData(index);
                              clean();
                            }}
                          >
                            {t(`views.sidebar.${name}`)}
                          </h4>
                        </a>
                      </Link>
                    ))}
                  </Card.Body>
                </div>
              )}
            </Card>
          )
        );
      })}
    </div>
  );
};
