import Button from "react-bootstrap/Button";
import Image from "next/image";
import Layout from "../components/Layout";
import Navbar from "react-bootstrap/Navbar";
import clsx from "clsx";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import styles from "../styles/unauthorized.module.scss";
import { useAuth } from "../src/context/auth";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

const UnathorizedPage = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { user } = useAuth();
  const handleGoBack = () => {
    if (!user) {
      router.replace("/login");
    } else {
      router.back();
    }
  };

  return (
    <Layout variant={"dark"}>
      <Navbar
        fixed="top"
        bg="dark"
        variant="dark"
        className={styles["navbar-padding"]}
      >
        <Navbar.Brand
          className={styles["navbar-brand"]}
          onClick={() => router.push("/dashboard")}
        >
          <Image
            alt="logo-white"
            src="/logo-white-vertical.svg"
            width={107}
            height={42}
          />
        </Navbar.Brand>
      </Navbar>
      <div className="d-flex justify-content-center align-items-center bg-dark flex-fill flex-column">
        <div className={styles["inner-container"]}>
          <Image
            alt="lock-img"
            src="/lock-img.svg"
            width={355}
            height={350}
            priority
          />
          <div className={styles["message-container"]}>
            <h6 className={clsx(styles.title, " text-center big-text-bold")}>
              {t("views.unauthorized.title")}
            </h6>
            <p className={styles.message}> {t("views.unauthorized.message")}</p>
          </div>
        </div>
        <Button
          variant="primary"
          onClick={() => handleGoBack()}
          className={styles.button}
        >
          {!user ? t("views.auth.backToLogin") : t("general.goBack")}
        </Button>
      </div>
    </Layout>
  );
};

export async function getStaticProps(ctx) {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale, ["common"])),
    },
  };
}

export default UnathorizedPage;
