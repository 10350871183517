import { createContext, useContext, useReducer } from "react";

const FiltersContext = createContext({});

const base = { view: "" };

const paginationBase = {
  pageSize: 10,
  pageCount: 0,
  offset: 0,
  orderBy: "id",
  direction: "desc",
};

export const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useReducer((state, newState) => {
    if (newState.clear) {
      removeItem("stored_filters");

      return base;
    } else if (newState.initial && newState.view === state.view) {
      return state;
    } else {
      const stateToBeSaved = {
        ...state,
        ...newState,
      };

      setItem("stored_filters", stateToBeSaved);

      return stateToBeSaved;
    }
  }, getInitialState());

  const [paginationData, setPaginationData] = useReducer((state, newState) => {
    if (newState.clear) {
      removeItem("stored_pagination");

      return paginationBase;
    } else if (newState.initial && newState.view === state.view) {
      return state;
    } else {
      const stateToBeSaved = {
        ...state,
        ...newState,
      };

      setItem("stored_pagination", stateToBeSaved);

      return stateToBeSaved;
    }
  }, getInitialPagination());

  const clean = () => {
    removeItem("stored_filters");
    removeItem("stored_pagination");
  };

  return (
    <FiltersContext.Provider
      value={{ clean, filters, setFilters, paginationData, setPaginationData }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => useContext(FiltersContext);

const getItem = (key) => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(key);
  }
};

const removeItem = (key) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(key);
  }
};

const setItem = (key, stateToBeSaved) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(stateToBeSaved));
  }
};

const getInitialState = () => {
  const stored_filters = getItem("stored_filters");
  return stored_filters ? JSON.parse(stored_filters) : base;
};

const getInitialPagination = () => {
  const stored_pagination = getItem("stored_pagination");
  return stored_pagination ? JSON.parse(stored_pagination) : paginationBase;
};
