import React, { forwardRef } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import PropTypes from "prop-types";
import { useRouter } from "next/router";

const LanguageSelector = ({ onChange, variant = "primary" }) => (
  <Dropdown align="end" className="me-2" onSelect={onChange}>
    <Dropdown.Toggle
      as={ToggleButton}
      variant={variant}
      id="dropdown-language"
    />

    <Dropdown.Menu aling="start" >
      <Dropdown.Item  eventKey="en">English</Dropdown.Item>
      <Dropdown.Item eventKey="es">Español</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

const ToggleButton = forwardRef(function Toggle({ onClick, variant }, ref) {
  const router = useRouter();
  const getColor = (variant) => {
    switch (variant) {
      case "primary":
        return '#818a91';
       
      case "secondary":
        return "white";
       
      case "dark":
        return "#333333";
        
      default:
        return null;
    }
  }

  const getBorderColor = (variant) => {
    switch (variant) {
      case "primary":
        return "#818a91";

      case "secondary":
        return "white";

      case "dark":
        return "#d0dde6";

      default:
        return null;
    }

  };
  return (
    <div className="d-flex">
      <a
        ref={ref}
        href=""
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="p-1 d-flex align-items-center justify-content-center"
        style={{
          height: 32,
          width: 76,
          borderStyle: "solid",
          borderWidth: '1px',
          borderColor: getBorderColor(variant),
          borderRadius: 6,
        }}
      >
        <i
          className="bi bi-globe"
          style={{
            fontSize: 20,
            margin: "10px 10px 10px 0",
            color:getColor(variant),
          }}
        ></i>
        <h4
          className="mt-0 mb-0"
          style={{
            margin: 0,
            color: getColor(variant)
          }}
        >
          <b>{router?.locale === "en" ? "ENG" : "ESP"}</b>{" "}
        </h4>
      </a>
    </div>
  );
});

LanguageSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default LanguageSelector;
