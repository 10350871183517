import React, { forwardRef } from "react";

import CustomBreadcrumb from "../Breadcrumbs";
import Dropdown from "react-bootstrap/Dropdown";
import LanguageSelector from "../LanguageSelector";
import Link from "next/link";
import LoginSelector from "../LoginSelector";
import Navbar from "react-bootstrap/Navbar";
import PropTypes from "prop-types";
import S3Image from "../S3Image";
import clsx from "clsx";
import styles from "./Header.module.scss";
import { useTranslation } from "next-i18next";

const CustomToggle = forwardRef(function Toggle({ onClick, user }, ref) {
  return (
    <a
      ref={ref}
      href=""
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="d-flex align-items-center"
    >
      <S3Image
        imageKey={user?.picture}
        alt="mdo"
        width="32"
        height="32"
        className="rounded-circle"
      />
    </a>
  );
});

const Header = ({
  handleSignOut,
  handleChangeLang,
  user,
  handleSelectLogin,
}) => {
  const { t } = useTranslation();

  return (
    <Navbar className={clsx(styles.navbar)} bg="light" sticky="top" expand>
      <div className="ms-4 p-0 container-fluid">
        <Navbar.Brand
          className="d-flex align-items-center"
          style={{ height: 26 }}
          role="none"
        >
          <CustomBreadcrumb />
        </Navbar.Brand>

        <div className="d-flex align-items-center">
          <LanguageSelector variant="dark" onChange={handleChangeLang} />
          <LoginSelector
            isLogged={!!user}
            variant="dark"
            onChange={handleSelectLogin}
          />

          <Dropdown align="end" className="me-2" data-qa="header-profile">
            <Dropdown.Toggle
              as={CustomToggle}
              id="dropdown-custom-components"
              user={user}
            />

            <Dropdown.Menu aaling="start">
              <Link href="/profile" passHref>
                <Dropdown.Item eventKey="2" data-qa="dp-item-profile">
                  {t("general.profile")}
                </Dropdown.Item>
              </Link>

              <Dropdown.Item
                eventKey="3"
                data-qa="dp-item-logout"
                onClick={() => {
                  localStorage.removeItem("line_job_workers");
                  handleSignOut();
                }}
              >
                {t("general.logout")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Navbar>
  );
};

Header.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
};

export default Header;
