import React, { useState } from "react";

import Form from "react-bootstrap/Form";
import NumberInput from "./NumberInput";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./TextInputWithFormik.module.scss";
import { useField } from "formik";
import { useTranslation } from "next-i18next";

/**
 * Text input integrated with formik. Support several types of inputs:
 * text, number, currency and password
 */
const TextInputWithFormik = ({
  label,
  required = false,
  generated = false,
  autoComplete = "off",
  containerClass = "",
  secondaryLabel = "",
  ...props
}) => {
  const [field, meta] = useField(props);

  const [showContent, setShowContent] = useState(false);

  const { t } = useTranslation();

  const { type } = props;

  const error = meta.touched && meta.error;
  const formControl = (
    <Form.Control
      className={type === "password" && styles["custom-form-control"]}
      isValid={meta.touched && !meta.error}
      isInvalid={error}
      {...field}
      {...props}
      autoComplete={autoComplete}
      as={type === "number" || type === "currency" ? NumberControl : props.as}
      type={type === "password" ? (showContent ? "text" : "password") : type}
    />
  );

  const PasswordControl = (
    <div
      className={clsx(
        styles["eye-container"],
        "d-flex ps-2 pe-2 border align-items-center"
      )}
    >
      {" "}
      <i
        role="button"
        style={{ fontSize: 20, color: "#818a91" }}
        className={showContent ? " bi bi-eye-fill" : "bi bi-eye-slash-fill"}
        onClick={() => setShowContent(!showContent)}
      ></i>
    </div>
  );

  return (
    <Form.Group
      className={!!containerClass ? containerClass : "d-flex flex-column mb-3"}
      controlId={props.name}
    >
      <Form.Label>
        {label}
        {` `}
        {generated && (
          <span className={styles["secondary-label"]}>
            {t("general.autogenerated")}
          </span>
        )}
        {!required && (
          <span className={styles.optional}>({t("general.optional")})</span>
        )}
        {secondaryLabel && (
          <span className={styles.optional}>({secondaryLabel})</span>
        )}
      </Form.Label>
      <div className="d-flex flex-row">
        {formControl}
        {type === "password" && PasswordControl}
      </div>
      {error && (
        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

TextInputWithFormik.propTypes = {
  /** Input's title */
  label: PropTypes.string.isRequired,
  /** This props display an error message if the input it's empty
   * and has been touched however this it's just UI behaviour
   */
  required: PropTypes.bool,
  /** By default it's value it's text, however you can specify it's value to be: number, currency or password */
  type: PropTypes.string,
  /** Special prop to display a label besides the title to mark the field as autgenerated */
  generated: PropTypes.bool,
  /** Prop to set autocomplete policy of the input */
  autoComplete: PropTypes.string,
  /** Replaces input's default container class completly */
  containerClass: PropTypes.string,
  /** Secondary title */
  secondaryLabel: PropTypes.string,
};

export default TextInputWithFormik;

const NumberControl = React.forwardRef(function Number(props, ref) {
  return <NumberInput ref={ref} {...props} />;
});
