import AWS from "aws-sdk";

AWS.config.update({
  region: process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
  accessKeyId: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY,
  secretAccessKey: process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY,
});


const CognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
  apiVersion: "2016-04-18",
  region: process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
});

const CognitoS3ServiceProvider = new AWS.S3({ 
  apiVersion: "2016-04-18",
  region: process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
});

export { AWS, CognitoIdentityServiceProvider, CognitoS3ServiceProvider }