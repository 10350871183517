import { useEffect, useState } from "react";

import DropdownToggle from "./DropdownToggle";
import { Form } from "react-bootstrap";
import SearchBox from "./SearchBox";
import { useTranslation } from "next-i18next";

const CheckboxFilter = ({
  title,
  selectedOptions = [],
  options = [],
  onChange,
  asInput = false,
  required = false,
  containerClass = "",
  disabled= false
}) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState("");

  const [filteredOptions, setFilteredOptions] = useState([]);

  const [innerSelection, setInnerSelection] = useState(selectedOptions);

  useEffect(() => {
    const newOptions = options.filter((o) =>
      o.label?.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredOptions(newOptions);
  }, [filter, options, innerSelection]);

  const getLabel = (selectedOptions) => {
    if (
      (!selectedOptions.length || selectedOptions.length === options.length) &&
      !asInput
    ) {
      return t("views.filters.showingAll");
    } else if (selectedOptions.length === 1) {
      return selectedOptions[0].label;
    } else {
      return selectedOptions.length + " " + t("views.filters.selected");
    }
  };

  return (
    <DropdownToggle
      title={title}
      label={getLabel(selectedOptions)}
      values={innerSelection}
      onChange={(values) => {
        onChange(values);
        setFilter("");
      }}
      primaryLabel={!!selectedOptions.length}
      resetOptions={() => {
        setInnerSelection(selectedOptions);
        setFilter("");
      }}
      lightLabel={!selectedOptions.length}
      asInput={asInput}
      required={required}
      containerClass={containerClass}
      disabled={disabled}
      filter={
        <div
          className="d-flex flex-column bg-white p-3 w-100"
          style={{
            maxHeight: 427,
            cursor: "default",
            width: 318
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <p
              className="pe-3 border-end "
              style={{ fontSize: 12, cursor: "pointer" }}
              onClick={() => setInnerSelection(options)}
            >
              {t("views.filters.selectedAll")}
            </p>
            <p
              className="ps-3"
              style={{ fontSize: 12, cursor: "pointer" }}
              onClick={() => setInnerSelection([])}
            >
              {t("views.filters.clearSelected")}
            </p>
          </div>
          <SearchBox
            value={filter}
            onChange={(text) => setFilter(text)}
            placeholder={t("views.filters.search") + " " + title}
            width={"100%"}
            hideIcon
            instantChange
          />

          <div
            className="px-2 pt-2 border border-secundary mt-3 mh-75 overflow-auto"
            style={{
              borderRadius: 3,
              boxShadow: "inset 0 -1px 5px 0 rgba(0, 0, 0, 0.2)",
            }}
          >
            {filteredOptions.map((option) => {
              const checked = !!innerSelection.find(
                (so) => so.value === option.value
              );

              return (
                <Form.Check
                  key={option.value}
                  type="checkbox"
                  checked={checked}
                  label={option.label}
                  onChange={() => {
                    const newValue = !checked;
                    const newSelection = Array.from(innerSelection);
                    if (newValue) {
                      newSelection.push(option);
                      setInnerSelection(newSelection);
                    } else {
                      setInnerSelection(
                        newSelection.filter((o) => o.value !== option.value)
                      );
                    }
                  }}
                  className="w-100 fw-bold mb-2"
                  style={{
                    fontSize: 14,
                    cursor: "pointer",
                    whiteSpace: "break-spaces"
                  }}
                />
              );
            })}
            {!filteredOptions.length && (
              <p className="mb-2 text-center">No options left</p>
            )}
          </div>
        </div>
      }
    />
  );
};

export default CheckboxFilter;
