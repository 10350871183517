import { useEffect, useMemo } from "react";
import {
  useExpanded,
  usePagination,
  useSortBy,
  useTable,
  useBlockLayout,
} from "react-table";
import { useSticky } from "react-table-sticky";

import EmptyMessage from "../EmptyMessage";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";
import styles from "./StickyTable.module.scss";
import { useTranslation } from "next-i18next";
import { getPaginationOptions } from "../../utils/array-utils";
// import clsx from "clsx";
import React from "react";
import styled from "styled-components";
import ActionsDropdown from "../ActionsDropdown";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Link from "next/link";

const Styles = styled.div`
  .table {
    border: 1px solid #ddd;

    .tr {
      white-space: nowrap;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      /* border-right: 1px solid #ddd; */
      background-color: #fff;
      overflow: hidden;
      height: 42px;

      :last-child {
        border-right: 0;
      }
    }

    .th {
      background-color: #f6f6f6;
      color: #444;
      font-size: 12px;
      white-space: pre;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        font-weight: 600;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }

      .header-new {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .row-new {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .left {
        justify-content: left;
      }

      .right {
        justify-content: right;
      }

      .center {
        justify-content: center;
      }
    }
  }
`;

const InnerStickyTable = ({
  loading,
  columns,
  data,
  // customCellStyles = [],
  paginationData = {},
  setPaginationData = () => {},
  totalPages = 0,
  emptyMessage = "",
  // width,
  totalActions = [],
}) => {
  const { t } = useTranslation();

  const pageSize = paginationData.pageSize || 10;

  const orderBy = useMemo(
    () => ({
      id: paginationData.orderBy,
      desc: paginationData.direction === "desc",
    }),
    [paginationData.orderBy, paginationData.direction]
  );

  const index = Math.ceil((paginationData.offset + 1) / pageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      pageCount: totalPages,
      initialState: {
        pageIndex: index - 1,
        pageSize: pageSize,
        sortBy: [orderBy],
      },
    },
    useBlockLayout,
    useSticky,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    const { id, desc } = sortBy[0] || {};
    if (id !== orderBy.id || desc !== orderBy.desc)
      setPaginationData({
        orderBy: id,
        direction: desc ? "desc" : "asc",
      });
  }, [sortBy, orderBy, setPaginationData]);

  const paginationOptions = getPaginationOptions(pageCount, index);

  const tabletSize = !!window && window?.innerWidth <= 1200;

  const handleClick = (e, action) => {
    if (!action.disabled) {
      action.onClick(e);
    }
  };

  return (
    <div className={styles.container}>

      <Styles>
        <div
          {...getTableProps()}
          className="table sticky"
          style={{ width: "100%", maxHeight: 479, background: 'white', fontSize: '14px' }}
        >
          <div className="header">
            {headerGroups.map((headerGroup, index) => (
              <div
                key={index}
                {...headerGroup.getHeaderGroupProps()}
                className="tr"
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <div key={index} {...column.getHeaderProps(column.getSortByToggleProps())} className="th">
                      <div className={`header-new ${column.isSorted ? styles["sorted-title"] : ""} ${column.align}`}>
                        <span>
                          {column.canSort &&
                            (column.isSorted && !column.isSortedDesc ? (
                              <i
                                className={`bi bi-caret-up-fill ${styles["icon"]}`}
                                style={{ color: "#244bc4" }}
                              />
                            ) : (
                              <i
                                className={`bi bi-caret-down-fill ${styles["icon"]}`}
                                style={{
                                  color: column.isSortedDesc ? "#244bc4" : "",
                                }}
                              />
                            ))}
                        </span>
                        {column.render("Header")}
                      </div>
                    </div>
                  )}
                )}
              </div>
            ))}
            
          </div>
          <div {...getTableBodyProps()} className="body" style={{ position: !loading ? 'relative' : 'absolute', width: '100%', border: 'none' }}>
            {page.map((row, rIndex) => {
              prepareRow(row);

              const finalActions = totalActions
                .filter(() => !row.canExpand)
                .map((action) => {
                  const { disable } = action;
                  const disabled = !(!disable || !disable(row.original));
                  return {
                    ...action,
                    disabled,
                    onClick: (e) => {
                      e.stopPropagation();
                      action.onClick(
                        action.withSubRow
                          ? {
                              ...row.original,
                              subRow: row.depth > 0,
                            }
                          : row.original
                      );
                    },
                    redirect: () => {
                      const path =
                        action.redirect && !disabled
                          ? action.redirect(row.original)
                          : "";
                      return path;
                    },
                  };
                });

              return (
                <div key={rIndex} {...row.getRowProps()} className="tr">
                  {row.cells.map((cell, cIndex) => {
                    if (!!totalActions.length && cell.column.id === "id") {
                      return (
                        <div key={cIndex} {...cell.getCellProps()} className="td">
                          <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                            {tabletSize ? (
                              <ActionsDropdown actions={finalActions} />
                            ) : (
                              finalActions.map((action) => {
                                const overlayProps = {
                                  placement: "bottom-end",
                                };
                                if (action.disabled) {
                                  overlayProps.show = false;
                                }

                                return (
                                  <LinkWrapper key={action.name} path={action.redirect()}>
                                    <OverlayTrigger
                                      {...overlayProps}
                                      overlay={
                                        <Tooltip id={`tooltip-${action.name}`}>
                                          {action.name}
                                        </Tooltip>
                                      }
                                    >
                                      <i
                                        role="button"
                                        className={`${action.icon} ${styles.action} ${
                                          action.disabled && styles["icon-disabled"]
                                        }`}
                                        onClick={(e) => handleClick(e, action)}
                                      />
                                    </OverlayTrigger>
                                  </LinkWrapper>
                                );
                              })
                            )}
                          </div>
                        </div>
                      )
                    }
                    return (
                      <div key={cIndex} {...cell.getCellProps()} className={`td ${(row.isExpanded || row.depth > 0) ? styles["expanded-color"] : ""}`}>
                        <div className={`row-new ${cell.column.align}`}>
                          <div style={{ minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {cell.render("Cell")}
                          </div>
                        </div>
                      </div>
                    )}
                  )}
                </div>
              );
            })}
            {!data.length && !loading && (
              <div
                style={{ height: 380 }}
                className={"border-secundary"}
              >
                <EmptyMessage
                  principal={t("views.table.noData")}
                  secondary={emptyMessage || t("views.table.noDataMessage")}
                  justSecundary={!!emptyMessage}
                />
              </div>
            )}
            {loading && (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: 380 }}
              >
                <Spinner animation="border" />{" "}
              </div>
            )}
          </div>
        </div>
      </Styles>

      {!!data.length && pageCount > 1 && !loading && (
        <div className={`pagination ${styles["pagination-container"]}`}>
          <button
            type="button"
            onClick={() => {
              previousPage();
              setPaginationData({
                offset: paginationData.offset - paginationData.pageSize,
              });
            }}
            disabled={index <= 1}
            className={styles["pagination-option"]}
          >
            {t("views.table.previous")}
          </button>{" "}
          {paginationOptions.map((option) =>
            option === "first_spacer" || option === "second_spacer" ? (
              <div
                className="d-flex align-items-center justify-content-center mx-2"
                key={option}
              >
                <p className="mb-0">{"..."}</p>
              </div>
            ) : (
              <button
                type="button"
                key={option}
                onClick={() => {
                  gotoPage(option);
                  setPaginationData({
                    offset:
                      (option + 1) * paginationData.pageSize -
                      paginationData.pageSize,
                  });
                }}
                className={`${styles["page-option"]} ${
                  index - 1 === option ? styles["selected-option"] : ""
                }`}
              >
                {option + 1}
              </button>
            )
          )}
          <button
            type="button"
            onClick={() => {
              nextPage();
              setPaginationData({
                offset: paginationData.offset + paginationData.pageSize,
              });
            }}
            disabled={index >= pageCount}
            className={styles["pagination-option"]}
          >
            {t("views.table.next")}
          </button>{" "}
        </div>
      )}
    </div>
  );
};

InnerStickyTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.any.isRequired,
      accessor: PropTypes.string.isRequired,
    }).isRequired
  ),
  data: PropTypes.array.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  customCellStyles: PropTypes.array,
  paginationData: PropTypes.object.isRequired,
  setPaginationData: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  emptyMessage: PropTypes.string,
};

export default InnerStickyTable;

// remove, it is repeated
const LinkWrapper = ({ path = "", children }) =>
  !!path ? (
    <Link passHref href={path}>
      <a className="text-reset">{children}</a>
    </Link>
  ) : (
    children
  );
