export const dataForCheckin = (worker, job, values, specialRates) => {
  let {
    value: positionValue,
    code: positionCode,
    base_rate: positionRate = 0,
  } = values?.position || {};

  const positionId = positionValue || worker.position_id || "";

  const locationId = job?.line?.location?.id;

  let extra;

  // If there are special rates rules for the worker's agency we choose the most specific one
  if (specialRates.length && worker.allow_special_rates) {
    // First we search for an exact match for the current  location +  position. This has top priority
    extra = specialRates.find(
      (sr) =>
        sr.special_rate_locations.find((l) => l.location_id === locationId) &&
        sr.special_rate_positions.find((p) => p.position_id === positionId)
    );

    // Then we try searching  for a rule that cover our current position but it's applied to all locations. Second level of priority
    if (!extra) {
      extra = specialRates.find(
        (sr) =>
          sr.special_rate_positions.find((p) => p.position_id === positionId) &&
          !sr.special_rate_locations.length
      );
    }

    // Up next we try searching for a rule that covers all the agency's loctions but it's applied to all positions. Third level of priority
    if (!extra) {
      extra = specialRates.find(
        (sr) =>
          sr.special_rate_locations.find((l) => l.location_id === locationId) &&
          !sr.special_rate_positions.length
      );
    }

    // If all else fails we search for a rule that covers all positions and all locations. Least priority
    if (!extra) {
      extra = specialRates.find(
        (sr) =>
          !sr.special_rate_locations.length && !sr.special_rate_positions.length
      );
    }
  }

  let { rate = 0, markup = 0 } = worker;

  if (positionCode === "LUMPER") {
    // Check if there it's a special lumper rate for current location
    const locationSpecialRates = worker.agency?.locations?.find(
      (l) => l.location_id === locationId
    );

    const locationRate =
      locationSpecialRates?.location?.location_positions?.find(
        (lp) => lp.position.code === "LUMPER"
      );

    const finalPositionRate = locationRate?.base_rate || positionRate || 0;

    rate = finalPositionRate > rate ? finalPositionRate : rate;
  }

  if (!!extra && worker.allow_special_rates) {
    const { differential = 0, markup: extraMarkup } = extra;
    rate += differential;
    if (extraMarkup || extraMarkup === 0) {
      markup = extraMarkup;
    }
  }

  return { positionId, rate, markup };
};
